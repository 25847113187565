import logoLight from "@/assets/image/logo-light.svg";
import logoDark from "@/assets/image/logo-dark.svg";
import iconMenuBtnLight from "@/assets/icon/icon-menu-btn-light.svg";
import iconMenuBtnDark from "@/assets/icon/icon-menu-btn-dark.svg";
import {Fragment, useEffect} from "react";
import {$} from "@/util/util";
import {Link, NavLink} from "react-router-dom";
import {MENU_DATA} from "@/menu_data";
import {useDispatch} from "react-redux";
import {openInquiryLayer} from "@/lib/reduxToolkit/inquiry";

/**
 * 1. 스크롤 수직 방향 체크하여 visibleHeader 컨트롤
 * 2. html 태그에 scrollTop 값 표시
 */
function scrollInfoCatch() {
  // console.log('scrollInfoCatch');
  
  const $header = document.querySelector('header');
  
  let lastScrollTop = 0;
  
  window.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    
    if (st >= $header.clientHeight) {
      if (st > lastScrollTop) {
        // 스크롤 아래로 내리면
        // console.log('downscroll code', st);
        visibleHeader(false);
      } else {
        // 스크롤 위로 올리면
        // console.log('upscroll code', st);
        visibleHeader(true);
      }
    }
    
    lastScrollTop = st <= 0 ? 0 : st;
    
    setScrollTopInfo();
    setHeaderTransparent();
  }, false);
  
  setScrollTopInfo();
  setHeaderTransparent();
}

/**
 * html 태그에 scrollTop 값 표시
 */
function setScrollTopInfo() {
  const $html = document.querySelector('html');
  $html.setAttribute('data-scroll-top', window.pageYOffset || document.documentElement.scrollTop);
}

/**
 * header 투명도 컨트롤
 */
function setHeaderTransparent() {
  const $html = document.querySelector('html');
  const st = $html.getAttribute('data-scroll-top');
  
  const $header = document.querySelector('header > section');
  if (!$header) return;
  
  if (st <= $header.clientHeight) {
    $html.setAttribute('data-header-transparent', 'true');
  } else {
    $html.setAttribute('data-header-transparent', 'false');
  }
}

/**
 *
 * @param is_visible
 */
function visibleHeader(is_visible) {
  const $header = document.querySelector('header');
  if(!$header) return;
  if (is_visible) {
    $header.classList.add('ACTIVE');
  } else {
    $header.classList.remove('ACTIVE');
  }
}

function CommonHeader() {
  const dispatch = useDispatch();

  useEffect(() => {
    // mount
    if (document.readyState == 'complete') {
      scrollInfoCatch();
    } else {
      window.addEventListener('load', () => {
        scrollInfoCatch();
      });
    }
    
    visibleHeader(true);
  }, [])
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  return (
    <header className={`
    max-xl:top-[calc(-1*var(--header-height-MOBILE))] xl:top-[calc(-1*var(--header-height-DESKTOP))] opacity-0
    [&.ACTIVE]:top-[0] [&.ACTIVE]:opacity-100
    w-full h-0
    sticky z-20 transition-all`}>
      <section className={`
      pt-[53px] xl:pt-[31px]
      h-[var(--header-height-MOBILE)] xl:h-[var(--header-height-DESKTOP)]
      [html[data-header-transparent="true"]_&]:bg-transparent [html[data-header-transparent="false"]_&]:bg-white/100
      [html[data-header-transparent="false"]_&]:shadow-[0px_2px_10px_rgba(0,0,0,0.1)]
      `}>
        <div className={`INSIDE
        grid gap-[0] items-center
        max-xl:grid-cols-[1fr,40px] xl:grid-cols-[263px,auto,132px]
        `}>
          {/*로고*/}
          <Link to="/" className={`block`}>
            <img className={`w-[188px] transition-all hidden
            [html[data-header-transparent="true"][data-header-logo="white"]_&]:block
            `} src={logoLight} alt=""/>
            <img className={`w-[188px] transition-all hidden
            [html[data-header-transparent="false"]_&]:block
            [html[data-header-logo="black"]_&]:block
            `} src={logoDark} alt=""/>
          </Link>
          {/*모바일 우측 메뉴 버트*/}
          <div className={`xl:hidden h-[24px]`}>
            <button className={`relative top-[-8px] w-[40px] h-[40px] flex flex-row items-center justify-center`} onClick={(evt) => {
              $('#mobileGnb').ACTIVE();
            }}>
              <img className={`hidden
            [html[data-header-transparent="true"][data-header-logo="white"]_&]:block
            `} src={iconMenuBtnLight} alt=""/>
              <img className={`hidden
            [html[data-header-transparent="false"]_&]:block
            [html[data-header-logo="black"]_&]:block
            `} src={iconMenuBtnDark} alt=""/>
            </button>
          </div>
          {/*GNB*/}
          <div id="gnb" className={`max-xl:hidden`}>
            <ul className={`space-x-[32px]
            [html[data-header-transparent="true"][data-header-logo="white"]_&]:text-white
            [html[data-header-transparent="false"]_&]:text-[var(--neutral-90)]
            [html[data-header-logo="black"]_&]:text-[var(--neutral-90)]
            `}>
              {
                MENU_DATA.map((obj, idx) => {
                  return (
                    <Fragment key={idx}>
                      {obj.GNB == true &&
                        (
                          <li className={`inline-block`}>
                            <NavLink to={obj.PAGE} className={`font-[400] [&.active]:font-[800] text-[14px] leading-[16px]`}>
                              {obj.뎁스1}
                            </NavLink>
                          </li>
                        )
                      }
                    </Fragment>
                  );
                })
              }
            </ul>
          </div>
          {/* DESKTOP 우측 Contact 버튼 */}
          <div className={`max-xl:hidden`}>
            <button className={`BTN BTN-WHITE w-full !hidden
            [html[data-header-transparent="true"][data-header-logo="white"]_&]:!inline-flex
            `} onClick={() => {
              dispatch(openInquiryLayer());
            }}>Contact</button>
            <button className={`BTN BTN-BLACK w-full !hidden
            [html[data-header-transparent="false"]_&]:!inline-flex
            [html[data-header-logo="black"]_&]:!inline-flex
            `} onClick={() => {
              dispatch(openInquiryLayer());
            }}>Contact</button>
          </div>
        </div>
      </section>
    </header>
  );
}

export default CommonHeader;