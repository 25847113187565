const section_4_data = [
  {
    active : false,
    icon : "icon-07-01",
    bg : "#f0e3d0",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "회원 관리",
    desc_1 : "CRM 관리\n 통합정보/활동이력 관리",
    desc_2 : "자사몰의 기본 회원관리 기능 뿐 아니라 SNS 회원 가입 등을 고려한 종합 CRM 기능을 구현하였습니다.",
    desc_3 : [
      "회원별 등급관리, 등급별 정책관리 기능",
      "일반회원 및 SNS 회원 가입 관리 기능",
      "회원별 포인트, 예치금 일괄 관리 기능",
      "자동메일, SMS, 카카오톡 메세지 관리 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-02",
    bg : "#f6f6f6",
    bg_hover : "rgba(255,255,255,1)",
    color : "#1a1a1f",
    title : "상품 관리",
    desc_1 : "통합상품관리\n 가격비교 시스템",
    desc_2 : "상품등록/운영 관리 기능은 물론, 운영에 있어 필요한 추가기능이 다수 제공 됩니다.",
    desc_3 : [
      "가격비교 사이트의 실시간 가격을 추출하여 표시해주는 가격비교 기능",
      "상품별, 브랜드별, 카테고리별  페이지 등록/진열 관리 기능",
      "옵션별 가격 차등 적용 노출 기능",
      "사은품 등록 / 증정 관리 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-03",
    bg : "#32363d",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#ffffff",
    title : "주문/배송 관리",
    desc_1 : "통합주문관리\n 원스탑배송관리",
    desc_2 : "국내 판매 및 해외판매, 역직구 모두 고려된 주문/배송 관리 시스템을 제공합니다.",
    desc_3 : [
      "제품의 교환/환불 ‘철회’ 기능",
      "반품/교환 시 배송비를 고객이 직접 결제하는 기능",
      "해외배송상품 2중으로 송장을 입력하고 프론트에 국내/해외 배송 표시 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-04",
    bg : "#638d7c",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#ffffff",
    title : "제휴 연동관리",
    desc_1 : "이벤트/프로모션 관리 \n 쿠폰관리",
    desc_2 : "실무 중 가장 큰 공수가 들어가는 부분 중 하나인, 제휴출점몰 관리를 자동으로 할 수 있게 됩니다.",
    desc_3 : [
      "외부 솔루션 (사방넷) 과  연동하여 상품 등록 및 주문 수집, 클레임 자동 수집기능",
      "사방넷 연계 출점몰 아이디별 관리기능",
      "수집 진행사항 모니터링 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-05",
    bg : "#e5e5e5",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "이벤트\n 프로모션 관리",
    desc_1 : "이벤트/프로모션 관리 \n 쿠폰관리",
    desc_2 : "이벤트/프로모션을 전개하기 위해 가장 효율적인 부분이 무엇인가 고민하였습니다.",
    desc_3 : [
      "쿠폰, 기획전, 이벤트 기능",
      "포인트, 마일리지, 예치금 관리 기능",
      "상품쿠폰, 장바구니쿠폰, 시리얼쿠폰,",
      "코드할인 기능\n 등급별 차등 노출, 구매, 할인 및 제한기능\n (폐쇄몰, 임직원몰 기능)",
    ],
  },
  {
    active : false,
    icon : "icon-07-06",
    bg : "#757a65",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#ffffff",
    title : "디자인 관리",
    desc_1 : "배너/팝업 진열 관리 \n 스킨관리",
    desc_2 : "수동관리를 최소화 할 수 있도록 고려한 배너 등록 및 진열 관리 기능을 제공합니다.",
    desc_3 : [
      "스킨별 설정 관리 기능",
      "영역별 배너 전시 관리 기능",
      "팝업 관리 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-07",
    bg : "#8bb3c3",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "통계 관리",
    desc_1 : "기간별 매출, 회원 데이터 조회\n 기간대비 데이터 분석",
    desc_2 : "단순히 일자별 판매 데이터가 아닌, 실제 md 운영 실무에 가장 필요한 데이터를 추출하여 제공합니다.",
    desc_3 : [
      "전년 동기대비 매출, 방문자 등 비교 기능",
      "브랜드, 카테고리별 매출 분석 기능",
      "이벤트, 기획전 별 회전율 분석 기능",
      "구글 GA 시스템을 바탕으로한 사이트 방문/이탈/체류 고객 분석 및 디바이스별 분석 기능 등",
    ],
  },
  {
    active : false,
    icon : "icon-07-08",
    bg : "#f6f6f6",
    bg_hover : "rgba(255,255,255,1)",
    color : "#1a1a1f",
    title : "마케팅 관리",
    desc_1 : "마케팅 채널 연동\n 가격비교 채널 연동",
    desc_2 : "외부 서비스와 손쉽게 연동 할 수 있도록 다양한 마케팅 연동기능을 제공합니다.",
    desc_3 : [
      "네이버쇼핑, 페이 연동 기능",
      "SNS 페이스북 픽셀 연동 기능",
      "구글광고 연동 기능",
      "리뷰 작성솔루션(크리마) 연동 기능 등",
    ],
  },
  {
    active : false,
    icon : "icon-07-09",
    bg : "#bfb8b3",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "공급사 관리",
    desc_1 : "공급사 (입점사) 관리",
    desc_2 : "자체 판매 뿐만 아니라 입점몰 형태로 운영이 가능하며, 관련된 주요 기능을 제공합니다.",
    desc_3 : [
      "공급사 등록/관리 기능",
      "공급사 게시글 관리 기능",
      "공급사 상품관리 기능",
      "공급사별 정산 관리 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-10",
    bg : "#babe9b",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "게시판 관리",
    desc_1 : "리뷰 최적화 관리\n 문의글 최적화 관리",
    desc_2 : "고객 문의 및 운영에 따른 게시판 관리 기능을 제공합니다.",
    desc_3 : [
      "고객 1:1 문의 게시글을 연속해서 다는 연속답변달기 기능",
      "답변이 최종 완료 되었는지의 상태를 고객이 한눈에 확인할 수 있도록 하는 답변 진행상황 표시 기능",
      "리뷰 실시간 베스트선정 및 리워드 기능",
      "동영상 리뷰 지원 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-11",
    bg : "#f0e3d0",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "설정 관리",
    desc_1 : "다양한 기능별 설정관리",
    desc_2 : "운영에 필요한 기본 설정을 디테일하게 분류하여 관리할 수 있는 기능",
    desc_3 : [
      "기본설정 기능",
      "상품설정 기능",
      "배송설정 기능",
      "주문설정 기능",
      "결제설정 기능",
    ],
  },
  {
    active : false,
    icon : "icon-07-12",
    bg : "#8b898d",
    bg_hover : "rgba(255,255,255,.2)",
    color : "#1a1a1f",
    title : "메인 대시보드 관리",
    desc_1 : "주요 데이터 요약 열람",
    desc_2 : "앱 푸쉬 메세지 예약 및 발송 관리 기능",
    desc_3 : [
      "회원별 앱 푸쉬 발송/관리 기능",
      "테스트메세지 발송 기능",
    ],
  },
];

const section_5_data = [
  {
    "0" : "구분",
    "1" : "커머스온",
    "2" : "A솔루션사",
    "3" : "B솔루션사",
    "4" : "C솔루션사",
    "5" : "D솔루션사"
  },
  {
    "0" : "독립형 유무",
    "1" : "지원 ",
    "2" : "지원",
    "3" : "지원",
    "4" : "지원",
    "5" : "지원"
  },
  {
    "0" : "입점형 유무",
    "1" : "지원 ",
    "2" : "없음",
    "3" : "지원",
    "4" : "지원",
    "5" : "지원"
  },
  {
    "0" : "FTP 지원",
    "1" : "지원",
    "2" : "지원",
    "3" : "지원",
    "4" : "지원",
    "5" : "지원"
  },
  {
    "0" : "SSH 지원",
    "1" : "지원",
    "2" : "지원(외부호스팅만)",
    "3" : "지원(외부호스팅만)",
    "4" : "지원(외부호스팅만)",
    "5" : "가능"
  },
  {
    "0" : "DB 외부접속지원",
    "1" : "지원",
    "2" : "PMA 이용",
    "3" : "PMA 이용",
    "4" : "PMA 이용",
    "5" : "가능"
  },
  {
    "0" : "PHP 버전",
    "1" : "8.1.4",
    "2" : "5.2x",
    "3" : "5.2x",
    "4" : "7.4x",
    "5" : "7.4x"
  },
  {
    "0" : "Mysql 버전",
    "1" : "MariaDb 10.6.10",
    "2" : "5.0 ~ 5.4",
    "3" : "5.5",
    "4" : "5.5.39\n mariaDB 가능 (v10.3x)",
    "5" : "5.2 이상"
  },
  {
    "0" : "web server 버전",
    "1" : "nginx v1.18.0",
    "2" : "Apache1.3xx ~ 2.0x",
    "3" : "Apache2.2x",
    "4" : "Apache2.2",
    "5" : "Apache1.4x ~ 2.0x"
  },
  {
    "0" : "프레임웍",
    "1" : "Laravel",
    "2" : "-"
  },
  {
    "0" : "자체 서버구성\n 가능여부",
    "1" : "지원",
    "2" : "불가",
    "3" : "지원(제약조건있음)",
    "4" : "지원",
    "5" : "지원(제약조건있음)"
  },
  {
    "0" : "솔루션비(기본셋팅)\n VAT 포함(단위 : 원)",
    "1" : "(없음) 개발비에 포함",
    "2" : "550,000",
    "3" : "14,000,000",
    "4" : "44,000,000",
    "5" : "88,000,000"
  },
  {
    "0" : "ERP연동(사방넷,이알피아)",
    "1" : "제한없음",
    "2" : "협의",
    "3" : "협의",
    "4" : "두손 ERP",
    "5" : "15,000,000 ~ 25,000,000"
  },
  {
    "0" : "물류시스템 여부",
    "1" : "WMS 구축 및 연동 가능",
    "2" : "없음",
    "3" : "발주,입고,출고,\n 반품, 주문서작성\n (일반적인형태)",
    "4" : "SJU 재고관리\n 창고관리\n 발주\n 안전배송\n 바코드 재고관리",
    "5" : "발주,입고,출고,\n 반품, 주문서작성, 창고관리\n 품목관리, 정산관리,\n 리셀러관리\n (일반적인형태)"
  },
  {
    "0" : "데이터이전\n 지원범위",
    "1" : "제한없음",
    "2" : "회원,상품,게시판\n (그 외에는 별도청구)",
    "3" : "회원,상품,게시판\n (그 외에는 별도청구)",
    "4" : "제한없음",
    "5" : "회원,상품,게시판\n (그 외에는 별도청구)"
  },
  {
    "0" : "고객비밀번호  암호화 방식",
    "1" : "Laravel Framework 제공\n Hash 라이브러리 사용\n 라이브러리 내 Bcrypt와 Argon2\n 알고리즘 적용",
    "2" : "MD5",
    "3" : "MD5",
    "4" : "MD5",
    "5" : "자체 암호화 알고리즘"
  },
];
export {
  section_4_data,
  section_5_data,
}