const section_2_data = [
  {
    title : "Blackyak",
    desc : "50년 전통의 아웃도어브랜드 기업\n blackyak kids, healcreek, nau, mountia 등\n 다수 브랜드 보유",
    img : "img-18-01",
  },
  {
    title : "Cowellfashion",
    desc : "대한민국 최대의 언더웨어 유통사\n adidas, puma, lacoste, calvin-klein, DKNY 등\n 다수 브랜드 보유",
    img : "img-18-02",
  },
];

const section_3_data = [
  {
    icon : "icon-14-01",
    title : "시스템 구축 \n System Construction",
    desc : "겉으론 비슷해 보이는 쇼핑몰들도 저마다의 \n" +
      "비지니스 모델을 지니고 있습니다. 그에 따라 \n" +
      "구축방식과 디테일이 달라져야 할 것입니다.  \n" +
      "자체구축, 독립몰, 임대몰, 구매대행, \n" +
      "직구, 역직구,  어떻게 전개 하시겠습니까?",
  },
  {
    icon : "icon-14-02",
    title : "운영지원 \n" +
      "Operation Support",
    desc : "운영은 시간과 비용과의 싸움이라해도 \n" +
      "과언이 아닐 것입니다. 판매/상품/컨텐츠관리,\n" +
      "제휴솔루션, ERP, WMS 연동 및 통합, 수동으로\n" +
      "처리하는 수많은 현업의 업무들을 시스템화 \n" +
      "해보세요.",
  },
  {
    icon : "icon-14-03",
    title : "확장\n" +
      "Extention",
    desc : "전략, 마케팅, 비지니스 모델, 효율성 증대, \n" +
      "새로운 시장에 대한 연구, 신기술 적용 등 \n" +
      "지금보다 더 먼 곳을 보고 오늘을 준비합니다.",
  }
];

const section_4_data = [
  {
    title : "모든것이 처음 시작되는 단계",
    desc : "아이디어와 자본은 있는데, 아직 시작하지 \n" +
      "못하셨다구요?  머리속에 있는 위대한 아이디어를 \n" +
      "가장 빠른 시간내에 현실화 할 수 있게. ",
    desc_2:"아이디어와 자본은 있는데, 아직 시작하지 못하셨다구요? \n" +
      "머리속에 있는 위대한 아이디어를 가장 빠른 시간내에 현실화 할 수 있게. ",
  },
  {
    title : "방향이 명확하지 않은 단계",
    desc : "이커머스 비지니스 방향을 잡고, 도전한 당신께 \n" +
      "박수를!  하지만 기획한 비지니스 전략을 효과적으로 \n" +
      "수행하기 위해 무엇부터 어떻게 해야할지 판단이 \n" +
      "되지 않으신다면.",
    desc_2:"이커머스 비지니스 방향을 잡고, 도전한 당신께 박수를!  하지만 기획한 비지니스 전략을 효과적으로 \n" +
      "수행하기 위해 무엇부터 어떻게 해야할지 판단이 되지 않으신다면.",
  },
  {
    title : "성장의 한계에 봉착한 단계",
    desc : "다년간 이커머스 사업을 진행중이지만, 운영과 \n" +
      "마케팅 방향성 등의 한게로 성장이 멈춘 상태.\n" +
      "체질 개선을 위한 처방이 필요하다면. ",
    desc_2:"다년간 이커머스 사업을 진행중이지만, 운영과 마케팅 방향성 등의 한계로 성장이 멈춘 상태.\n" +
      "체질 개선을 위한 처방이 필요하다면. ",
  },
  {
    title : "더 새롭게 도전하는 단계",
    desc : "지금까지 성공적으로 비지니스를 이끌어 오셨군요. \n" +
      "이커머스와 연계한 더 다양한 가능성을 만들어내고 싶으시다면.",
    desc_2:"지금까지 성공적으로 비지니스를 이끌어 오셨군요. \n" +
      "이커머스와 연계한 더 다양한 가능성을 만들어내고 싶으시다면.",
  },
];

const section_5_data = [
  {
    icon : "icon-16-01",
    title : "운영 지원 시스템 구축 경험",
    how : [
      "가격 비교 시스템",
      "로봇자동화 시스템",
      "주문관리 시스템",
      "O2O 시스템",
    ],
  },
  {
    icon : "icon-16-02",
    title : "다양한 시스템 구축 경험",
    how : [
      "SCM 구축",
      "WMS 구축",
      "API 연동",
      "ERP 연동",
    ],
  },
  {
    icon : "icon-16-03",
    title : "다양한 이커머스 플랫폼 경험",
    how : [
      "국내 이커머스",
      "해외직구",
      "역직구",
    ],
  },
];

const section_7_data = [
  {
    title : "01 전략기획 & 컨설팅",
    desc : "이커머스 전략, 규모/업종별 서비스 기획, 디지털 \n" +
      "플랫폼 기획, 운영 효율 분석 등  비지니스의 방향 \n" +
      "설정부터 디테일한 실행요소 및 노하우까지 \n" +
      "심도 있는 컨설팅을 지원합니다.",
    desc_2 : [
      "E-commerce strategy",
      "Business Service plan",
      "Digital Platform",
      "Webflow and Wordpress",
      "Content creation",
      "Web hosting",
    ],
  },
  {
    title : "02 브랜딩 & 디자인 ",
    desc : "현대자동차, 로레알 등 글로벌 기업과 함께한 브랜딩 경험을 바탕으로 귀사의 레퍼런스를 한껏 높여드립니다. 단순한 디자인 지원을 넘어 비전과 전략에 부합되는 한차원 높은 브랜딩 서비스를 만나보세요.",
    desc_2 : [
      "Brand Strategy",
      "Corporate Identity",
      "Campaigns",
      "Art Direction",
      "Photography",
      "Graphic Design",
    ],
  },
  {
    title : "03 시스템 구축",
    desc : "쇼핑몰 개발부터 ERP/WMS 구축 및 연동, 어플리케이션 개발(네이티브&하이브리드), RPA(로봇자동화) 시스템 구현, 이커머스 통합 시스템 구축 등 이커머스 시스템에 필요한 모든 서비스 제공이 가능합니다. ",
    desc_2 : [
      "ShoppingMall Development",
      "RPA System Support",
      "Intergrated System Development",
      "ERP Connection",
      "WMS Connection",
      "WMS Development",
    ],
  },
  {
    title : "04 운영지원 & 분석",
    desc : "대형 쇼핑플랫폼 및 글로벌 브랜드의 운영실무진(MD) 구성으로 실제 이커머스에서 일어나는 일들의 디테일한 기획과 분석. 유지보수의 차원을 넘어 마케팅과 프로모션 기획까지 지원이 가능합니다. ",
    desc_2 : [
      "CRM  Support & Development",
      "Sales Management Support",
      "Variable Promotion",
      "Traffic & Log Analysis",
      "Google Analytics",
      "System Maintenance",
    ],
  },
];

export {
  section_2_data,
  section_3_data,
  section_4_data,
  section_5_data,
  section_7_data,
}