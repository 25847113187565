import LayerPopBasicInquiry from "@/layer_pop/LayerPopBasicInquiry";

const LayerPopData = [
  {
    title : "일반 문의",
    element : LayerPopBasicInquiry,
    file_name : 'LayerPopBasicInquiry',
  },
];

export default LayerPopData;