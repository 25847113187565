import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {BrowserRouter} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";
import '@/css/main.css';
/* 2023-01-03 KSM : swiper */
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "@/css/swiperjs_addon.css";
/* // 2023-01-03 KSM : swiper */
import ScrollToTop from "@/common/ScrollToTop";
import MarkupInfo from "@/common/MarkupInfo";
import AppInfo from "@/common/AppInfo";
import {Provider} from 'react-redux';
import {store} from '@/lib/reduxToolkit/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MarkupInfo/>
      <AppInfo/>
      <ScrollToTop/>
      <App/>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
