import {Outlet} from "react-router-dom";
import CommonLayerPop from "@/common/CommonLayerPop";
function LayoutBasic() {
  return (
    <>
      <main>
        <Outlet/>
      </main>
      <CommonLayerPop></CommonLayerPop>
    </>
  );
}

export default LayoutBasic;