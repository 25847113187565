import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

let wid, setWid, hei, setHei;

window.addEventListener("resize", (evt) => {
  setSize();
});

function setSize() {
  setWid(window.innerWidth);
  setHei(window.innerHeight);
}

function MarkupInfo({}) {
  
  const us_wid = useState(window.innerWidth);
  wid = us_wid[0];
  setWid = us_wid[1];
  
  const us_hei = useState(window.innerHeight);
  hei = us_hei[0];
  setHei = us_hei[1];
  
  useEffect(() => {
    // mount
    setSize();
  }, [])
  
  return (
    <div className={`MarkupInfo`}>
      <Link to="/PageSitemap" className={`hidden [[data-env-node-env="development"]_&]:block text-[12px] text-[yellow] fixed top-[0] left-[50%] z-[9999] bg-black/70 p-1 translate-x-[-50%] cursor-pointer`}>
        {wid} x {hei}
      </Link>
    </div>
  );
}

export default MarkupInfo;