import {Link} from "react-router-dom";
import React, {Fragment} from "react";
import {Header, toJsxStrArray} from "@/util/util";
import {section_2_data, section_3_data, section_4_data} from "@/page/PageDesign.data";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
function PageDesign({title}) {
  Header.setLogoColor('white');
  
  return (
    <div data-title={title} className={`PageDesign`}>
      {/* 2023-01-09 :: START :: KSM : SECTION-1 */}
      <section className={`bg-[#413C39] text-white
      pt-[calc(36px+var(--header-height-MOBILE))] pb-[40px]
      xl:pt-[calc(32px+var(--header-height-DESKTOP))] xl:pb-[42px]
      `}>
        <div className={`INSIDE`}>
          <div className={`
          font-[600] text-[16px] leading-[24px] max-xl:text-center
          xl:text-[24px] xl:leading-[36px]
          `}
               data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
          >
            DESIGN IS MARKETING
          </div>
          <div className={`xl:mt-[10px] xl:grid xl:grid-cols-2 xl:gap-[0] items-end`}>
            <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}>
              <div className={`ONLY-MOBILE mt-[14px] font-[500] text-[36px] leading-[44px] text-center`}>
                의도를 가장<br/>
                효과적으로<br/>
                전달할 수 있는<br/>
                디자인을 제안합니다.
              </div>
              <div className={`ONLY-DESKTOP font-[400] text-[48px] leading-[56px]`}>
                의도를 가장<br/>
                효과적으로 전달할 수 있는<br/>
                디자인을 제안합니다.
              </div>
            </div>
            <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 3}>
              <div className={`ONLY-MOBILE font-[400] text-[14px] leading-[20px] mt-[21px] text-center`}>
                판매하는 카테고리에 따라,<br/>
                목표로 하는 고객층에 따라 디자인은 달라져야 합니다.<br/>
                <br/>
                커머스온은 다년간의 패션/럭셔리 이커머스 시스템<br/>
                구축 프로젝트를 통해 고객의 니즈에 맞춰 아이디어회의,<br/>
                디자인, 퍼블리싱의 전 과정을 최적화하여<br/>
                최상의 결과물을 제안합니다.
              </div>
              <div className={`ONLY-DESKTOP font-[400] text-[20px] leading-[30px]`}>
                판매하는 카테고리에 따라,<br/>
                목표로 하는 고객층에 따라 디자인은 달라져야 합니다.<br/>
                <br/>
                커머스온은 다년간의 패션/럭셔리 이커머스 시스템 구축 프로젝트를 통해<br/>
                고객의 니즈에 맞춰 아이디어회의, 디자인, 퍼블리싱의 전 과정을 최적화하여<br/>
                최상의 결과물을 제안합니다.
              </div>
            </div>
          </div>
          <div className={`text-center xl:hidden`}>
            <Link className={`mt-[38px] BTN BTN-BLACK w-[var(--mobile-width-limit)]`}>Contact</Link>
          </div>
        </div>
      </section>
      {/* // 2023-01-09 :: END :: KSM : SECTION-1 */}
      
      {/* 2023-01-12 :: START :: KSM : SECTION-2-MOBILE */}
      <section className={`ONLY-MOBILE
      pt-[36px] pb-[45px]
      `} data-seq="20230126120205286">
        <div className={`INSIDE`}>
          <div className={`space-y-[35px]`}>
            {
              section_2_data.map((obj1, idx1) => {
                return (
                  <Fragment key={idx1}>
                    <div className={``}>
                      <div>
                        <img className={`drop-shadow-[0px_2px_20px_rgba(0,0,0,0.2)] w-full`} src={require(`@/assets/image/${obj1.img}.png`)} alt=""/>
                      </div>
                      <div className={`mt-[12px] font-[600] text-[14px] leading-[16px] text-black`}>
                        {obj1.title}
                      </div>
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-12 :: END :: KSM : SECTION-2-MOBILE */}
      
      {/* 2023-01-12 :: START :: KSM : SECTION-2-DESKTOP */}
      <section className={`ONLY-DESKTOP pb-[73px] bg-white overflow-hidden`} data-seq="20230126164849784">
        <div className={``}>
          <Swiper className={`SWIPER-1 !pl-[calc((var(--scrollbar-width)-var(--app-min-width-DESKTOP))/2)]`}
                  modules={[Autoplay, Pagination, Navigation]}
                  pagination={{
                    el : '#pagination-23-01-12',
                    type : "fraction",
                  }}
                  navigation={{
                    enabled : true,
                    nextEl : '#navigation-next-23-01-12',
                    prevEl : '#navigation-prev-23-01-12',
                  }}
                  slidesPerView={"auto"}
                  spaceBetween={30}
                  autoplay={{
                    delay : 2500,
                  }}
                  rewind={true}>
            {
              section_2_data.map((obj, idx) => {
                return (
                  <SwiperSlide key={idx} className={`pt-[40px] !w-[450px]`}>
                    <div>
                      <img className={`drop-shadow-[0_2px_20px_rgba(0,0,0,.2)]`} src={require(`@/assets/image/${obj.img}.png`)} alt=""/>
                    </div>
                    <div className={`mt-[33px]`}>
                      <span className={`TITLE font-[600] text-[14px] leading-[16px] text-black underline`}>
                        {obj.title}
                      </span>
                    </div>
                  </SwiperSlide>
                );
              })
            }
          </Swiper>
          <div className={`mt-[50px] relative z-10 grid grid-cols-[50px,60px] gap-[0] items-center justify-start w-full max-w-[1280px] mx-auto`}>
            <div className={`relative text-center`}>
              <div id="pagination-23-01-12" className={`my-pagination type-4`}></div>
            </div>
            <div className={`grid grid-cols-2 gap-[0]`}>
              <div id="navigation-prev-23-01-12" className={`my-navigation prev type-4`}>PREV</div>
              <div id="navigation-next-23-01-12" className={`my-navigation next type-4`}>NEXT</div>
            </div>
          </div>
        </div>
        
      </section>
      {/* // 2023-01-12 :: END :: KSM : SECTION-2-DESKTOP */}
      
      {/* 2023-01-17 :: START :: KSM : SECTION-3 */}
      <section data-seq="20230126154711926">
        {
          section_3_data.map((obj1, idx1) => {
            const img_bg = require(`@/assets/image/${obj1.img_bg}.png`);
            
            return (
              <Fragment key={idx1}>
                <div className={`relative group`}>
                  <div className={`z-10 absolute top-[0] left-[0] w-full h-[50%] text-[0]`} style={{backgroundColor : obj1.title_bg}}
                       data-aos="blind-up" data-aos-offset="0" data-aos-anchor-placement="top-center"
                  >BG
                  </div>
                  <div className={`xl:grid xl:grid-cols-2 xl:gap-[0]`}>
                    {/*텍스트 박스*/}
                    <div className={`xl:group-even:order-1
                pt-[30px] px-[var(--app-contents-padding-MOBILE)] pb-[41px]
                xl:pt-[189px] xl:px-[80px] xl:pb-[190px] xl:h-[800px]
                `}
                         style={{backgroundColor : obj1.title_bg}}>
                      <div className={`h-full flex flex-col justify-between`}>
                        {/* 상단 텍스트 */}
                        <div className={`font-[400]
                    text-[24px] leading-[36px]
                    xl:text-[40px] leading-[56px]
                    `}
                             style={{color : obj1.title_color}}
                             data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 1}>
                          {obj1.title}
                        </div>
                        {/* 하단 텍스트 */}
                        <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}>
                          <div className={`font-[600]
                    max-xl:mt-[93px] text-[16px] leading-[24px]
                    xl:text-[24px] xl:leading-[36px]
                    `} style={{color : obj1.title_color}}>
                            {toJsxStrArray(obj1.desc)}
                          </div>
                          <div className={`mt-[24px] font-[300] text-[16px] leading-[26px] `} style={{color : obj1.title_color}}>
                            {toJsxStrArray(obj1.desc_2)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*이미지 박스*/}
                    <div className={`bg-cover bg-center
                          pt-[56px] pb-[56px] px-[20px]
                          xl:pt-[189px] xl:h-[800px]
                        `}
                         style={{backgroundImage : `url(${img_bg})`}}>
                      <div className={`mx-auto
                      w-full
                      xl:w-[560px]`}>
                        <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 3}>
                          {/*이미지*/}
                          <div>
                            <img className={`w-full`} src={require(`@/assets/image/${obj1.img}.png`)} alt=""/>
                          </div>
                          {/*이미지 하단 버튼*/}
                          <div className={`mt-[10px] text-right text-white space-x-[4px]`}>
                            <a href={obj1.link_mobile} target="_blank" className={`BTN-TYPE-0`}>웹</a>
                            <a href={obj1.link_web} target="_blank" className={`BTN-TYPE-0`}>모바일</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })
        }
      </section>
      {/* // 2023-01-17 :: END :: KSM : SECTION-3 */}
      
      {/* 2023-01-17 :: START :: KSM : SECTION-4 */}
      <section className={`
      pt-[34px] pb-[82px]
      xl:pt-[115px] pb-[155px]
      `} data-seq="20230126155610700">
        <div className={`INSIDE`}>
          <ul className={`
          max-xl:space-y-[34px]
          xl:grid xl:grid-cols-2 xl:gap-x-[147px] xl:gap-y-[114px]
          `}>
            {
              section_4_data.map((obj1, idx1) => {
                return (
                  <Fragment key={idx1}>
                    <li className={``} data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * idx1}>
                      <div className={`bg-[#EAEAEA] text-center py-[27px]`}>
                        <img className={`inline-block w-[81.87%] xl:w-[468px]`} src={require(`@/assets/image/${obj1.img}.png`)} alt=""/>
                      </div>
                      <div className={`mt-[10px] flex flex-row items-center justify-between`}>
                        <div className={`font-[600] text-[16px] leading-[24px] text-[var(--neutral-90)]`}>
                          {obj1.title}
                        </div>
                        <div className={`text-right text-white space-x-[4px]`}>
                          <a href={obj1.link_mobile} target="_blank" className={`BTN-TYPE-1`}>웹</a>
                          <a href={obj1.link_web} target="_blank" className={`BTN-TYPE-1`}>모바일</a>
                        </div>
                      </div>
                    </li>
                  </Fragment>
                );
              })
            }
          </ul>
        </div>
      </section>
      {/* // 2023-01-17 :: END :: KSM : SECTION-4 */}
    </div>
  );
}

export default PageDesign;