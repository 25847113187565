import {$} from "@/util/util";
import {Fragment, useEffect, useRef} from "react";
import LayerPopData from "@/layer_pop_data";
import {Link, useLocation} from "react-router-dom";
import _ from "lodash";
import "./PageLayerPopSample.css";

function PageLayerPopSample({title}) {
  
  const layer_refs = useRef([]);
  
  useEffect(() => {
    // update
    setPreviewLayer();
  });
  
  /**
   * 미리보기용 레이어 팝업 복제 후 보이게 처리
   */
  function setPreviewLayer() {
    layer_refs.current.forEach((obj, idx) => {
      _.map(obj.children, (preview_layer) => {
        preview_layer.removeAttribute(`id`);
        $(preview_layer).addClass('PREVIEW');
      });
    });
  }
  
  /* 2023-01-15 :: START ::  KSM : 페이지 진입시에 hash 값 가져와서 스크롤 */
  const hash = useLocation().hash;
  
  $(document).on('readystatechange', '230116', (evt) => {
    if (document.readyState === 'complete') {
      // console.log("document.readyState : ", document.readyState);
      // let hash = window.location.hash.split('#');
      // if (hash.length < 3) return;
      // hash = `#${hash.pop()}`;
      
      const el_target = document.querySelector(`[data-id="${hash}"]`);
      if (!el_target) return;
      const y = el_target.offsetTop;
      window.scrollTo({
        top : y, left : 0, behavior : 'smooth',
      });
    }
  })
  /* // 2023-01-15 :: END :: KSM : 페이지 진입시에 hash 값 가져와서 스크롤 */
  
  return (<div data-title={title} className={`PageLayerPopSample`}>
    {LayerPopData.map((obj, idx) => {
      const layer_pop_name = obj.file_name;
      const layer_id = `#${layer_pop_name}`;
      const preview_img = `${process.env.PUBLIC_URL}/layer_pop/${layer_pop_name}.png`;
      const LayerPopTag = obj.element;
      
      return (<Fragment key={idx}>
        <section data-id={layer_id} className={`p-[10px]`}>
          <Link to={layer_id} className={`BTN BTN-PRIMARY`} onClick={(evt) => {
            /*
            const el_layer = document.querySelector(`.CommonLayerPop #${layer_pop_name}`);
            $(el_layer).addClass('ACTIVE');
            */
            $(layer_id).ACTIVE();
          }}>
            {layer_pop_name} : {obj.title}
          </Link>
          <input className={`mt-[5px] block border-[1px] border-solid border-black w-full p-1`}
                 type="text"
                 readOnly={true}
                 defaultValue={`/src/layer_pop/${layer_pop_name}.jsx`}
                 onClick={(evt) => {
                   evt.target.closest('input').select();
                 }}/>
          <div className={`mt-[5px]`} ref={(item) => {
            layer_refs.current[idx] = item;
          }}>
            <LayerPopTag></LayerPopTag>
          </div>
        </section>
      </Fragment>);
    })}
  </div>);
}

export default PageLayerPopSample;