import {Link} from "react-router-dom";
import {Fragment} from "react";
import {MENU_DATA} from "@/menu_data";
function PageSitemap({}) {
  
  return (
    <div className={`PageSitemap p-5 bg-gray-200`}>
      <ul className={`text-[20px]`}>
        {MENU_DATA &&
          MENU_DATA.map((obj1, idx1) => {
            
            return (
              <Fragment key={idx1}>
                <li data-depth="뎁스1" className={`p-1 hover:outline-[1px] hover:outline-dashed hover:outline-amber-600/50 hover:bg-amber-300/50`}>
                  {obj1.PAGE ?
                    (<Link className={`text-[blue] hover:underline block`} to={`/${obj1.PAGE}`}>{obj1.뎁스1}</Link>)
                    :
                    (<div>{obj1.뎁스1}</div>)
                  }
                  <ul className={`pl-[20px]`}>
                    {
                      obj1.child.map((obj2, idx2) => {
                        return (
                          <Fragment key={idx2}>
                            <li data-depth="뎁스2">
                              {obj2.PAGE ?
                                (<Link className={`text-[blue] hover:underline block`} to={`/${obj2.PAGE}`}>{obj2.뎁스2}</Link>)
                                :
                                (<div>{obj2.뎁스2}</div>)
                              }
                              <ul className={`pl-[20px]`}>
                                {
                                  obj2.child.map((obj3, idx3) => {
                                    return (
                                      <Fragment key={idx3}>
                                        <li data-depth="뎁스3">
                                          {obj3.PAGE ?
                                            (<Link className={`text-[blue] hover:underline block`} to={`/${obj3.PAGE}`}>{obj3.뎁스3}</Link>)
                                            :
                                            (<div>{obj3.뎁스3}</div>)
                                          }
                                        </li>
                                      </Fragment>
                                    );
                                  })
                                }
                              </ul>
                            </li>
                          </Fragment>
                        );
                      })
                    }
                  </ul>
                </li>
              </Fragment>
            );
          })
        }
      
      </ul>
    </div>
  );
}

export default PageSitemap;