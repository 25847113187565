import {useEffect} from "react";
import {useLocation} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ScrollToTop({title}) {
  /* 2023-01-20 :: START ::  KSM : 페이지 전환시 스크롤 탑 */
  const {pathname} = useLocation();
  
  useEffect(() => {
    // update
    window.scrollTo({
      top : 0,
      left : 0,
      behavior : 'auto',
    });
    
  }, [pathname]);
  /* // 2023-01-20 :: END :: KSM : 페이지 전환시 스크롤 탑 */
  
  /* 2023-01-20 :: START ::  KSM : scroll 파라메터 읽어서 스크롤 */
  let query = useQuery();
  const scrollTargetId = query.get('scroll');
  
  useEffect(() => {
    // update
    const el_partnerListSec = document.querySelector(`#${scrollTargetId}`);
    if (el_partnerListSec) {
      if (document.readyState === 'complete') {
        moveScroll();
      } else {
        document.addEventListener('readystatechange', () => {
          if (document.readyState === 'complete') {
            moveScroll();
          }
        });
      }
      
      function moveScroll() {
        setTimeout(() => {
          const top = el_partnerListSec.offsetTop;
          console.log(`#${scrollTargetId} - top : ${top}`);
          window.window.scrollTo({
            top : top,
            left : 0,
            behavior : 'smooth',
          });
        },500);
      }
    }
  }, [query]);
  /* // 2023-01-20 :: END :: KSM : scroll 파라메터 읽어서 스크롤 */
  
  return null;
}

export default ScrollToTop;