import React, {Fragment, useState} from "react";
import {section_2_data} from "@/page/PageCustomerService.data";
import {activeOneItem, Header, toJsxStrArray} from "@/util/util";
import {openInquiryLayer} from '@/lib/reduxToolkit/inquiry';
import {useDispatch} from 'react-redux';
function PageCustomerService({title}) {
  Header.setLogoColor('white');
  
  const us_sec_2_data = useState(section_2_data);
  const sec_2_data = us_sec_2_data[0];

  const dispatch = useDispatch();
  
  return (
    <div data-title={title} className={`PageCustomerService`}>
      {/* 2023-01-09 :: START :: KSM : SECTION-1 */}
      <section className={`bg-[var(--neutral-90)] text-white
      pt-[calc(36px+var(--header-height-MOBILE))] pb-[40px]
      xl:pt-[calc(32px+var(--header-height-DESKTOP))] xl:pb-[42px]
      `}>
        <div className={`INSIDE`}>
          <div className={`
          font-[600] text-[16px] leading-[24px] max-xl:text-center
          xl:text-[24px] xl:leading-[36px]
          `}
               data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
          >
            FAQ
          </div>
          <div className={`xl:mt-[10px] xl:grid xl:grid-cols-2 xl:gap-[0] items-end`}>
            <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}>
              <div className={`ONLY-MOBILE mt-[14px] font-[500] text-[36px] leading-[44px] text-center`}>
                쇼핑몰에 대해<br/>
                자주 묻는 질문<br/>
                무엇이든 물어보세요!
              </div>
              <div className={`ONLY-DESKTOP font-[400] text-[48px] leading-[56px]`}>
                쇼핑몰에 대해<br/>
                자주 묻는 질문<br/>
                무엇이든 물어보세요!
              </div>
            </div>
          </div>
          <div className={`text-center xl:hidden`}>
            <button className={`mt-[38px] BTN BTN-WHITE w-[var(--mobile-width-limit)]`} onClick={() => {
              dispatch(openInquiryLayer());
            }}>Contact</button>
          </div>
        </div>
      </section>
      {/* // 2023-01-09 :: END :: KSM : SECTION-1 */}
      {/* 2023-01-17 :: START :: KSM : SECTION-2 */}
      <section className={`pt-[23px] pb-[90px]`} data-seq="20230126120509624">
        
        <div className={`INSIDE`}>
          <div className={`xl:grid xl:grid-cols-[1fr,480px] xl:gap-[0]`}>
            <ul>
              {
                sec_2_data.map((obj1, idx1) => {
                  return (
                    <Fragment key={idx1}>
                      <li className={`relative cursor-pointer ${obj1.active && 'ACTIVE'}`} onClick={() => {
                        activeOneItem(us_sec_2_data, obj1);
                      }}>
                        <div className={`[li:not(:first-child)_&]:border-t-[1px] [li:not(:first-child)_&]:border-b-[#F2F2F2] py-[14px]`} data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * idx1}>
                          <div className={`flex flex-row items-start justify-between`}>
                            <div className={`font-[400] text-[18px] leading-[26px] text-[var(--neutral-90)] pr-[20px]`}>
                              {obj1.question}
                            </div>
                            <div className={`w-[20px] min-w-[20px] h-[24px] bg-[url(@/assets/icon/icon-12.svg)] bg-cover bg-center bg-no-repeat text-[0] transition-all
                          [.ACTIVE_&]:rotate-180
                          `}>ICON
                            </div>
                          </div>
                          <div className={`overflow-hidden font-[300] text-[16px] leading-[26px] text-[var(--neutral-70)]
                      max-h-[0]
                      [.ACTIVE_&]:max-h-[1000px] [.ACTIVE_&]:block [.ACTIVE_&]:mt-[21px] `}>
                            {toJsxStrArray(obj1.answer)}
                          </div>
                        </div>
                      </li>
                    </Fragment>
                  );
                })
              }
            </ul>
            <div className={`
            text-center
            xl:text-right xl:pr-[40px]
            `}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >
              <button className={`border-[1px] border-solid border-[#e1e1e1]
            max-xl:mt-[30px]
            pt-[32px] px-[37px] pb-[36px]
            xl:w-[340px]
            `} onClick={() => {
                dispatch(openInquiryLayer());
              }}>
                <div>
                  <img src={require(`@/assets/icon/icon-13.svg`).default} alt=""/>
                </div>
                <div className={`underline whitespace-nowrap mt-[44px] font-[400] text-[20px] leading-[28px] text-[var(--neutral-90)]`}>
                  궁금증이 해결되지 않으셨나요?
                </div>
              </button>
            </div>
            {/*<div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
              <div className={`ONLY-MOBILE mt-[30px]`}>
                <button onClick={(evt) => {
                  $('#LayerPopProjectInquiry').ACTIVE();
                }}>
                  <img src={require(`@/assets/image/img-14.png`)} alt=""/>
                </button>
              </div>
              <div className={`ONLY-DESKTOP cursor-pointer text-right pr-[40px]`}>
                <button onClick={(evt) => {
                  $('#LayerPopProjectInquiry').ACTIVE();
                }}>
                  <img className={`inline-block`} src={require(`@/assets/image/img-14-pc.png`)} alt=""/>
                </button>
              </div>
            </div>*/}
          </div>
        </div>
      </section>
      {/* // 2023-01-17 :: END :: KSM : SECTION-2 */}
    </div>
  );
}

export default PageCustomerService;