import CommonHeader from "../common/CommonHeader";
import CommonAside from "../common/CommonAside";
import CommonFooter from "../common/CommonFooter";
import {Outlet} from "react-router-dom";
import CommonLayerPop from "@/common/CommonLayerPop";
function LayoutBasic() {
  return (
    <>
      <CommonHeader></CommonHeader>
      <CommonAside></CommonAside>
      <main>
        <Outlet/>
      </main>
      <CommonFooter></CommonFooter>
      <CommonLayerPop></CommonLayerPop>
    </>
  );
}

export default LayoutBasic;