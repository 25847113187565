import {Link} from "react-router-dom";
import {openInquiryLayer} from "@/lib/reduxToolkit/inquiry";
import {useDispatch} from "react-redux";
function CommonFooter() {
  const dispatch = useDispatch();

  return (
    <footer className={`bg-[var(--neutral-90)] text-white pt-[35px] pb-[30px] xl:pt-[41px] xl:pb-[32px]`}>
      <section>
        <div className={`INSIDE`}>
          <div className={`font-[500] text-[14px] leading-[16px] text-white`}>
            커머스온 고객센터
          </div>
          <div className={`
          mt-[18px] grid grid-cols-[167px,144px] gap-[9px]
          xl:mt-[16px] xl:grid xl:grid-cols-[180px,156px] xl:gap-[10px]
          `}>
            <Link to={`/PageCustomerService`} className={`BTN BTN-WHITE w-full`}>
              고객센터 바로가기
            </Link>
            <button className={`BTN BTN-WHITE w-full`} onClick={() => {
              dispatch(openInquiryLayer());
            }}>문의 접수하기</button>
          </div>
          <address className={`
          mt-[18px] font-[400] text-[12px] leading-[16px] text-white
          xl:mt-[16px]
          `}>
            <div className={`xl:grid xl:grid-cols-[repeat(2,auto)] xl:gap-[10px] xl:justify-start`}>
              <div>(주)커머스온 대표이사 : 주복낙</div>
              <div>사업자등록번호 : 119-87-09155</div>
            </div>
            <div>서울특별시 마포구 양화로 186 LC타워(홍대입구) 4층 404호</div>
          </address>
          <div className={`border-t-[1px] border-t-white/30 mt-[23px] xl:mt-[21px]`}></div>
          {/*로고*/}
          <div className={`mt-[18px] xl:mt-[24px] xl:grid xl:grid-cols-[repeat(2,auto)] xl:gap-[0] text-[0] items-center justify-between`}>
            <div>
              <img className={`w-[320px] xl:w-[187px]`} src={require(`@/assets/image/logo-light.svg`).default} alt=""/>
            </div>
            <ul className={`mt-[22px] xl:mt-[0] space-x-[20px]`}>
              <li className={`inline-block font-[600] underline text-[12px] leading-[16px] text-white`}>
                <Link to="/PagePersonalDataPolicy">개인정보처리방침</Link>
              </li>
              <li className={`inline-block font-[400] text-[12px] leading-[16px] text-white`}>
                <button onClick={() => {
                  dispatch(openInquiryLayer({type: 'alliance'}));
                }}>제휴&마케팅제안</button>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default CommonFooter;
