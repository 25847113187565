import './App.css';
import {Link, NavLink} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@/css/aos_addon.css'
import RouteSetting from "@/route-setting";
import {Fragment, useEffect} from "react";
import {$} from "@/util/util";
import {MENU_DATA} from "@/menu_data";
import {openInquiryLayer} from '@/lib/reduxToolkit/inquiry';
import {useDispatch} from 'react-redux';

/**
 *
 */
function aosInit() {
  
  // https://github.com/michalsnik/aos
  AOS.init({
    // disable : function () {
    //   // 1280 미만일 때 disable
    //   return window.innerWidth < 1280;
    // },
    duration : 300,
    easing : 'ease-out-cubic',
    // anchorPlacement : 'center-bottom',
  });
  
  document.addEventListener('aos:in', ({detail}) => {
    // console.log('animated in', detail);
  });
  
  document.addEventListener('aos:out', ({detail}) => {
    // console.log('animated out', detail);
  });
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // mount
    if (document.readyState == 'complete') {
      aosInit();
    } else {
      window.addEventListener('load', () => {
        aosInit();
      });
    }
    
  }, [])
  
  return (
    <div className={`App min-w-[var(--app-min-width-MOBILE)] bg-[var(--body-background-color)] md:max-xl:grid md:max-xl:grid-cols-2 md:max-xl:gap-[6%] md:max-xl:justify-center`}>
      <div id="appLeftArea" className={`hidden md:max-xl:block`}>
        <div className={`pl-[6%] flex flex-col items-center justify-center h-screen z-10 sticky top-[0] left-[0]`}>
          <div>
            <img className={`w-[435px]`} src={require(`@/assets/image/logo-light.svg`).default} alt=""/>
          </div>
          <div className={`mt-[27px]`}>
            <img src={require(`@/assets/image/img-06.png`)} alt=""/>
          </div>
          <div className={`w-full max-w-[386px] mt-[10%] grid grid-cols-2 gap-[6px]`}>
            <button className={`BTN BTN-WHITE col-span-2`} onClick={(evt) => {
              dispatch(openInquiryLayer());
            }}>Contact
            </button>
            <Link to={`PageCustomerService`} className={`BTN BTN-WHITE`}>고객센터 바로가기</Link>
            <button className={`BTN BTN-WHITE`} onClick={(evt) => {
              dispatch(openInquiryLayer());
            }}>문의 접수하기
            </button>
          </div>
        </div>
      </div>
      {/* 2023-01-20 :: START :: KSM : mobileGnb */}
      <div id="mobileGnb" className={`fixed z-30 top-[0] left-[-200%] [&.ACTIVE]:left-[0px] w-full h-full overflow-auto bg-black transition-all`}>
        <div className={`RR h-screen mx-auto pt-[53px] px-[20px] pb-[100px]`}>
          <div className={`grid grid-rows-[auto,auto] gap-[0] h-full content-between`}>
            <div className={`grid grid-cols-[auto,auto] gap-[0] items-center justify-between`}>
              <div>
                <img src={require(`@/assets/image/logo-light.svg`).default} alt=""/>
              </div>
              <button onClick={(evt) => {
                $('#mobileGnb').INACTIVE();
              }}>
                <img src={require(`@/assets/icon/icon-09.svg`).default} alt=""/>
              </button>
            </div>
            <ul className={`space-y-[13px]`}>
              {
                MENU_DATA.map((obj1, idx1) => {
                  if (obj1.GNB) {
                    return (
                      <Fragment key={idx1}>
                        <li className={`text-center`}>
                          <NavLink to={obj1.PAGE} className={`inline-block font-[600] text-[36px] leading-[44px] text-white text-center
                        relative before:hidden [&.active]:before:inline-block before:w-full before:h-[1px] before:bg-white before:content-[''] before:z-0 before:absolute before:bottom-[0px] before:left-[0px] before:text-center
                        `} onClick={(evt) => {
                            $('#mobileGnb').INACTIVE();
                          }}>
                            {obj1.뎁스1}
                          </NavLink>
                        </li>
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </ul>
            <div className={`text-center`}>
              <Link className={`BTN BTN-WHITE w-[var(--mobile-width-limit)]`} onClick={(evt) => {
                $('#mobileGnb').INACTIVE();
                dispatch(openInquiryLayer());
              }}>Contact</Link>
            </div>
          </div>
        </div>
      </div>
      {/* // 2023-01-20 :: END :: KSM : mobileGnb */}
      <div id="page" className={`bg-white xl:min-w-[var(--app-min-width-DESKTOP)]`}>
        <RouteSetting/>
      </div>
    </div>
  );
}

export default App;