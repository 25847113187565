import emailjs from '@emailjs/browser';
import React from 'react';
import {ENV} from '@/util/env';

export async function sendInquiry(data) {
  const type = data.type;
  const templateId = type === "build-site" ? ENV.EMAIL_JS.TEMPLATE_ID.PROJECT_INQUIRY : ENV.EMAIL_JS.TEMPLATE_ID.GENERAL_INQUIRY;

  //네이밍방식을 Atny React 프로젝트와 일치하기위해, 보내기 전 데이터 필드값을 카멜케이스로 작성하고, API 호출할 때는 _로 작성합니다.
  const sendData = {
    inquiry_type: INQUIRY_TYPES.find(({value}) => value === data.type)?.name,
    from_name: data.name,
    from_email: data.email,
    from_contact: data.contact,
    budget: data.budget,
    business_type: data.category,
    reference_site: data.referenceSite,
    message: data.content
  };

  return emailjs.send(ENV.EMAIL_JS.SERVICE_ID, templateId, sendData, ENV.EMAIL_JS.PUBLIC_KEY);
}

//유효성검증을 위해 값을 따로 분리했습니다.
export const INQUIRY_TYPES = [
  {
    value: "default",
    name: "문의하실 내용을 선택해주세요"
  },
  {
    value: "operation",
    name: "운영/컨설팅 문의"
  },
  {
    value: "build-site",
    name: "사이트구축/프로젝트 문의"
  },
  {
    value: "alliance",
    name: "제휴/마케팅/광고문의"
  },
  {
    value: "maintenance",
    name: "유지보수 문의"
  },
  {
    value: "etc",
    name: "기타문의"
  },
];
