import img_07_01 from "@/assets/image/img-07-01.png";
import img_07_02 from "@/assets/image/img-07-02.png";
import img_09 from "@/assets/image/img-09.png";
import {Header, $, toJsxStrArray, activeOneItem, inactiveAllItem} from "@/util/util";
import {Link} from "react-router-dom";
import {section_4_data, section_5_data} from "@/page/PageIntroduction.data";
import React, {Fragment, useRef, useState} from "react";

function determineDirection(ev, el) {
  let w = el.offsetWidth,
    h = el.offsetHeight,
    x = (ev.pageX - el.offsetLeft - (w / 2) * (w > h ? (h / w) : 1)),
    y = (ev.pageY - el.offsetTop - (h / 2) * (h > w ? (w / h) : 1)),
    d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
  if (d == 0) d = 'TOP';
  if (d == 1) d = 'RIGHT';
  if (d == 2) d = 'BOTTOM';
  if (d == 3) d = 'LEFT';
  
  return d;
}

function PageIntroduction({title}) {
  Header.setLogoColor('black');
  
  const us_sec_4_data = useState(section_4_data);
  const sec_4_data = us_sec_4_data[0];
  const setSec4Data = us_sec_4_data[1];
  
  const onMouseEnter = (evt) => {
    const el_li = evt.target.closest('li');
    const dir = determineDirection(evt, el_li);
    el_li.setAttribute('data-direction', `${dir}_IN`);
  }
  
  const onMouseLeave = (evt) => {
    const el_li = evt.target.closest('li');
    const dir = determineDirection(evt, el_li);
    el_li.setAttribute('data-direction', `${dir}_OUT`);
  }
  
  return (<div data-title={title} className={`PageIntroduction`}>
    {/* 2023-01-09 :: START :: KSM : SECTION-1 */}
    <section className={`
      pt-[calc(36px+var(--header-height-MOBILE))] pb-[40px]
      xl:pt-[calc(32px+var(--header-height-DESKTOP))] xl:pb-[42px]
      `}>
      <div className={`INSIDE`}>
        <div className={`
          font-[600] text-[16px] leading-[24px] text-[var(--neutral-90)] max-xl:text-center
          xl:text-[24px] xl:leading-[36px]
          `}
             data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
        >
          COMMERCEON SOLUTION
        </div>
        <div className={`xl:mt-[10px] xl:grid xl:grid-cols-2 xl:gap-[0] items-end`}>
          <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}>
            <div className={`ONLY-MOBILE mt-[14px] font-[500] text-[36px] leading-[44px] text-[var(--neutral-90)] text-center`}>
              운영자와 고객 모두를<br/>
              고려한 디테일한 기능<br/>
              커머스온샵만의<br/>
              차별화된 특장점을<br/>
              만나보세요.
            </div>
            <div className={`ONLY-DESKTOP font-[400] text-[48px] leading-[56px] text-[var(--neutral-90)]`}>
              운영자와 고객 모두를 고려한<br/>
              디테일한 기능 커머스온샵만의<br/>
              차별화된 특장점을 만나보세요.
            </div>
          </div>
          <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 3}>
            <div className={`ONLY-MOBILE font-[400] text-[14px] leading-[20px] text-[var(--neutral-90)] mt-[21px] text-center`}>
              커머스온샵은 쇼핑의 새로운 가치 창조를 목표로 합니다.<br/>
              단순히 쇼핑몰을 만들고 그치는 것이 아닌, 쇼핑몰에서<br/>
              고객이 함께 브랜드의 가치를 만들어 나갈 수 있는<br/>
              가장 빠른 길을 찾고자 노력합니다.
            </div>
            <div className={`ONLY-DESKTOP font-[400] text-[20px] leading-[30px] text-[var(--neutral-90)]`}>
              커머스온샵은 쇼핑의 새로운 가치 창조를 목표로 합니다.<br/>
              단순히 쇼핑몰을 만들고 그치는 것이 아닌,<br/>
              쇼핑몰에서 고객이 함께 브랜드의 가치를 만들어 나갈 수 있는<br/>
              가장 빠른 길을 찾고자 노력합니다.
            </div>
          </div>
        </div>
        <div className={`text-center xl:hidden`}>
          <Link className={`mt-[38px] BTN BTN-BLACK w-[var(--mobile-width-limit)]`}>
            Contact
          </Link>
        </div>
      </div>
    </section>
    {/* // 2023-01-09 :: END :: KSM : SECTION-1 */}
    
    {/* 2023-01-09 :: START :: KSM : SECTION-2 */}
    <section className={`bg-[#6D6A72] pt-[30px] pb-[30px]`}>
      <div className="INSIDE">
        {/*bg-[url("@/assets/image/img-08.png")] w-[320px] h-[240px] gap-[12px]*/}
        <div className={`bg-center bg-cover flex flex-row items-center justify-center
        bg-[url("@/assets/image/img-08.png")] w-full gap-[3.75%] pt-[40px] pb-[40px]
        xl:bg-[url("@/assets/image/img-08-pc.png")] xl:w-[1280px] xl:gap-[83px] xl:pt-[83px] xl:pb-[83px]
        `}>
          <div className={`w-[67.81%] xl:w-[660px] drop-shadow-[0_2px_20px_rgba(0,0,0,.2)]`}>
            {/* drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2)); */}
            <img src={img_07_01} alt=""
                 data-aos="fade-left" data-aos-offset="0" data-aos-delay={200}
            />
          </div>
          <div className={`w-[17.5%] xl:w-[171px] drop-shadow-[0_2px_20px_rgba(0,0,0,.2)]`}>
            <img src={img_07_02} alt=""
                 data-aos="fade-left" data-aos-offset="0" data-aos-delay={200 * 2}
            />
          </div>
        </div>
      </div>
    </section>
    {/* // 2023-01-09 :: END :: KSM : SECTION-2 */}
    
    {/* 2023-01-09 :: START :: KSM : SECTION-3 */}
    <section className={`
    pt-[31px] pb-[35px]
    xl:pt-[55px] xl:pb-[59px]
    `}>
      <div className={`INSIDE`}>
        <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
          <div className={`ONLY-MOBILE font-[600] text-[36px] leading-[44px] text-[var(--neutral-90)] text-center`}>
            커머스온은<br/>
            이렇게 다릅니다<br/>
            세부기능 알아보기
          </div>
          <div className={`ONLY-DESKTOP font-[600] text-[48px] leading-[56px] text-[var(--neutral-90)] text-center`}>
            커머스온은 이렇게 다릅니다<br/>
            세부기능 알아보기
          </div>
        </div>
        <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
          <div className={`ONLY-MOBILE mt-[17px] font-[300] text-[14px] leading-[24px] text-[var(--neutral-90)] text-center`}>
            각 세부 메뉴별로 판매와 운영에 필요한 핵심 기능을<br/>
            담았습니다. 각 기능들은 클릭 한 번을 더 줄여주기 위해<br/>
            수많은 파트너사의 실무자들과 고민을 거듭한 결과물<br/>
            입니다. 커머스온샵 만의 차별화 된 다양한<br/>
            기능들을 만나보세요.
          </div>
          <div className={`ONLY-DESKTOP mt-[27px] font-[400] text-[20px] leading-[30px] text-[var(--neutral-90)] text-center`}>
            각 세부 메뉴별로 판매와 운영에 필요한 핵심 기능을 담았습니다.<br/>
            각 기능들은 클릭 한 번을 더 줄여주기 위해 수많은 파트너사의 실무자들과 고민을 거듭한 결과물 입니다.<br/>
            커머스온샵 만의 차별화 된 다양한 기능들을 만나보세요.
          </div>
        </div>
      </div>
    </section>
    {/* // 2023-01-09 :: END :: KSM : SECTION-3 */}
    
    {/* 2023-01-09 :: START :: KSM : SECTION-4-MOBILE */}
    <section className={'ONLY-MOBILE'}>
      <ul className={`w-full mx-auto grid grid-cols-2 gap-[0] auto-rows-[180px]`}>
        {sec_4_data.map((obj1, idx1) => {
          return (<Fragment key={idx1}>
            <li className={`relative cursor-pointer
            pt-[13px] pb-[23px] px-[19px]
            flex flex-col items-start justify-between
            [&.ACTIVE]:col-span-2 [&.ACTIVE]:row-span-2
            ${obj1.active && 'ACTIVE'}
            `} style={{
              backgroundColor : obj1.bg, color : obj1.color, order : (() => {
                if (obj1.active && idx1 % 2 == 1) {
                  return idx1 - 1;
                } else {
                  return idx1 + 1;
                }
              })(),
            }} onClick={(evt) => {
              activeOneItem(us_sec_4_data, obj1);
            }}>
              <div className={`grid grid-cols-[24px,1fr] gap-[5px] items-start`}>
                <div>
                  <img className={`w-[24px] h-[24px]`} src={require(`@/assets/icon/${obj1.icon}.svg`)} alt=""/>
                </div>
                <div className={`[.ACTIVE_&_br]:hidden font-[600] text-[16px] leading-[24px]`}>
                  {obj1.title}
                </div>
              </div>
              <div className={`[.ACTIVE_&]:hidden font-[400] text-[12px] leading-[16px]`}>
                {toJsxStrArray(obj1.desc_1)}
              </div>
              <div className={`hidden [.ACTIVE_&]:block font-[300] text-[16px] leading-[26px]`}>
                <div>
                  특화기능 설명 :
                </div>
                <div>
                  {toJsxStrArray(obj1.desc_2)}
                </div>
                <br/>
                <div>
                  {obj1.desc_3.map((obj2, idx2) => {
                    return (<Fragment key={idx2}>
                      <div className={`
                        relative pl-[calc(10px+5px)] before:inline-block before:w-[10px] before:h-[26px] before:content-['-'] before:z-0 before:absolute before:top-[0px] before:left-[0px] before:text-center
                        `}>
                        {toJsxStrArray(obj2)}
                      </div>
                    </Fragment>);
                  })}
                </div>
              </div>
              <button className={`hidden [.ACTIVE_&]:block z-10 absolute top-[0] right-[0]`} onClick={(evt) => {
                evt.stopPropagation();
                inactiveAllItem(us_sec_4_data);
              }}>
                <img src={img_09} alt=""/>
              </button>
            </li>
          </Fragment>);
        })}
      </ul>
    </section>
    {/* // 2023-01-09 :: END :: KSM : SECTION-4-MOBILE */}
    
    {/* 2023-01-09 :: START :: KSM : SECTION-4-DESKTOP */}
    <section className={'ONLY-DESKTOP'}>
      <ul className={`max-w-[1440px] mx-auto grid grid-cols-3 gap-[0]`}>
        {sec_4_data.map((obj1, idx1) => {
          return (<Fragment key={idx1}>
            <li className={`ITEM-0112 relative cursor-pointer aspect-square group
            pt-[32px] pb-[43px] px-[45px]
            flex flex-col items-start justify-between
            `}
                style={{backgroundColor : obj1.bg, color : obj1.color,}}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
              {/*덮는 배경*/}
              <div className={`BG absolute z-0`} style={{backgroundColor : obj1.bg_hover}}></div>
              <div className={`relative z-10 grid grid-cols-[42px,1fr] gap-[10px] items-start`}>
                <div className={''}>
                  <img className={`w-[42px] h-[42px]`} src={require(`@/assets/icon/${obj1.icon}.svg`)} alt=""/>
                </div>
                <div className={`font-[600] text-[32px] leading-[42px]`}>
                  {obj1.title}
                </div>
              </div>
              <div className={`group-hover:hidden font-[400] text-[18px] leading-[26px]`}>
                {toJsxStrArray(obj1.desc_1)}
              </div>
              <div className={`relative z-20 hidden group-hover:block font-[300] text-[16px] leading-[26px]`}>
                <div>
                  특화기능 설명 :
                </div>
                <div>
                  {toJsxStrArray(obj1.desc_2)}
                </div>
                <br/>
                <div>
                  {obj1.desc_3.map((obj2, idx2) => {
                    return (<Fragment key={idx2}>
                      <div className={`
                        relative pl-[calc(10px+5px)] before:inline-block before:w-[10px] before:h-[26px] before:content-['-'] before:z-0 before:absolute before:top-[0px] before:left-[0px] before:text-center
                        `}>
                        {toJsxStrArray(obj2)}
                      </div>
                    </Fragment>);
                  })}
                </div>
              </div>
            </li>
          </Fragment>);
        })}
      </ul>
    </section>
    {/* // 2023-01-09 :: END :: KSM : SECTION-4-DESKTOP */}
    
    {/* 2023-01-09 :: START :: KSM : SECTION-5 */}
    <section className={`bg-[var(--neutral-90)]
    pt-[35px] pb-[67px]
    xl:pt-[74px] xl:pb-[174px]
    `}>
      <div className={`INSIDE`}>
        <div className={`text-white text-center`}>
          <div className={`ONLY-MOBILE font-[600] text-[36px] leading-[44px]`}>
            커머스온샵<br/>
            솔루션 지원범위<br/>
            및 스펙 비교
          </div>
          <div className={`ONLY-DESKTOP font-[600] text-[48px] leading-[56px]`}>
            커머스온샵 솔루션<br/>
            지원범위 및 스펙 비교
          </div>
        </div>
      </div>
      <div className={`mt-[39px] px-[20px] overflow-auto`}>
        {
          section_5_data.map((obj, idx) => {
            // let bg_color, color = null;
            // let font_size = '10px';
            // if (idx == 0) {
            //   bg_color = '#8b898d';
            //   color = 'white';
            // }
            
            return (
              <Fragment key={idx}>
                <div className={`text-center bg-white w-[1280px] mx-auto
                  grid grid-cols-6 auto-rows-auto gap-[0]
                  border-b-[1px] first:border-t-[1px] border-solid border-[var(--neutral-90)]
                  ${idx == 0 && 'TITLE'}
                  `}>
                  {
                    Array.from(Array(6)).map((obj2, idx2) => {
                      return (
                        <Fragment key={idx2}>
                          <div className={`
                            font-[300] text-[10px] leading-[13px] text-black
                            [.TITLE_&]:bg-[#8b898d] [.TITLE_&]:text-white
                            flex flex-row items-center justify-center
                            pt-[14px] pb-[14px] border-r-[1px] first:border-l-[1px] border-solid border-[var(--neutral-90)]
                            [&.STRONG]:!bg-[#3E47DD] [&.STRONG]:text-white
                            xl:[&.STRONG]:!bg-[#FFAE4F]
                            ${idx2 == 1 && 'STRONG'}
                            `}>{toJsxStrArray(obj[idx2])}</div>
                        </Fragment>
                      );
                    })
                  }
                </div>
              </Fragment>
            );
          })
        }
      </div>
    </section>
    {/* // 2023-01-09 :: END :: KSM : SECTION-5 */}
  </div>);
}

export default PageIntroduction;