import LayerPopBasicInquiry from '@/layer_pop/LayerPopBasicInquiry';
import {shallowEqual, useSelector} from 'react-redux';

function CommonLayerPop({title}) {
  const {visible, type} = useSelector(state => state.inquiry.props, shallowEqual);

  return (
    <div data-title={title} className={`CommonLayerPop`}>
      {!visible ? null : <LayerPopBasicInquiry type={type}/>}
    </div>
  );
}

export default CommonLayerPop;
