import {createSlice} from '@reduxjs/toolkit';
import {ENV} from '@/util/env';
import {init} from '@emailjs/browser';

const initialState = {
  initialized: false,
  props: {
    visible: false,
    type: 'default'
  }
};

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    openInquiryLayer: (state, action) => {
      if (!state.initialized) {
        init(ENV.EMAIL_JS.PUBLIC_KEY);
        state.initialized = true;
      }

      state.props = {
        visible: true,
        type: action.payload?.type ?? 'default',
      };
    },
    closeInquiryLayer: (state) => {
      state.props = {
        visible: false,
        type: 'default',
      };
    }
  }
});

export default inquirySlice.reducer;
export const {openInquiryLayer, closeInquiryLayer} = inquirySlice.actions;
