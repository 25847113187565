import React, {Fragment, useEffect} from "react";
import {$, Header, setHScrolling, toJsxStrArray} from "@/util/util";
import {section_2_data, section_3_data, section_4_data, section_5_data, section_7_data} from "@/page/PageConsulting.data";
import {Link} from "react-router-dom";
import {openInquiryLayer} from '@/lib/reduxToolkit/inquiry';
import {useDispatch} from 'react-redux';

function PageConsulting({title}) {
  const dispatch = useDispatch();

  $(window).on(`scroll`, `20230124102343812`, () => {
    setHScrolling('#hScrollWapper');
    setAos();
  })
  useEffect(() => {
    return () => {
      // unmount
      $(window).off(`scroll`, `20230124102343812`);
    }
  });
  
  Header.setLogoColor('white');
  
  useEffect(() => {
    // mount
    setHScrolling('#hScrollWapper');
    setAos();
  }, [])
  
  function setAos() {
    const el_hScrollWapper = document.querySelector('#hScrollWapper');
    const el_experience_box = el_hScrollWapper.querySelector('.EXPERIENCE-BOX');
    const el_aos_list = el_hScrollWapper.querySelectorAll('[data-aos]');
    
    const x = el_experience_box.getBoundingClientRect().x;
    const triiger_x = window.innerWidth / 2;
    if (x < triiger_x) {
      $(el_aos_list).addClass('aos-animate');
    } else {
      $(el_aos_list).removeClass('aos-animate');
    }
  }
  
  return (
    <div data-title={title} className={`PageConsulting`}>
      {/* 2023-01-05 :: START :: KSM : SECTION-1 */}
      <section className={`relative bg-cover bg-center
      pt-[190px] bg-[url("@/assets/image/bg1.png")]
      xl:pt-[183px] xl:bg-[url("@/assets/image/bg1-pc.png")]
      `} data-seq="20230126162959778">
        <div className={`relative INSIDE
        pb-[30px]
        xl:pb-[257px]`}>
          <div className={`ONLY-MOBILE`}>
            <div className={`font-[600] text-[32px] leading-[42px] text-white`}>
              기업이<br/>
              생각하는 것이<br/>
              실현 되도록
            </div>
            <div className={`mt-[16px] font-[600] text-[16px] leading-[24px] text-white`}>
              이커머스 비지니스는 생각할 것이 많습니다.<br/>
              온오프라인, O2O, 마케팅, 제휴, 물류, 연동 등<br/>
              모든 경험을 갖춘 파트너가 필요할 것입니다.
            </div>
          </div>
          <div className={`ONLY-DESKTOP`}>
            <div className={`font-[600] text-[72px] leading-[76px] text-white`}>
              기업이 생각하는 것이<br/>
              실현 되도록
            </div>
            <div className={`mt-[32px] font-[600] text-[24px] leading-[36px] text-white`}>
              이커머스 비지니스는 생각할 것이 많습니다.<br/>
              온오프라인, O2O, 마케팅, 제휴, 물류, 연동 등 모든 경험을 갖춘<br/>
              파트너가 필요할 것입니다.
            </div>
          </div>
          <div className={`
          mt-[110px]
          xl:mt-[32px]`}>
            <button className={`BTN BTN-PRIMARY
            w-full
            xl:w-[132px]`} onClick={() => {
              dispatch(openInquiryLayer());
            }}>Contact
            </button>
          </div>
          <div className={`ONLY-DESKTOP z-10 absolute right-[0] bottom-[0] bg-[var(--neutral-90)] w-[897px] h-[224px] pt-[32px] px-[40px]`}>
            <div>
              <div className={`Work-Sans font-[600] text-[12px] leading-[14px] text-white`}>
                PROJECT IN PROGRESS
              </div>
              <div className={`mt-[34px] grid grid-cols-2 gap-[40px]`}>
                {
                  section_2_data.map((obj2, idx2) => {
                    return (
                      <Fragment key={idx2}>
                        <div className={`grid grid-cols-[144px,1fr] gap-[24px]`}>
                          <div>
                            <img className={`w-[144px] h-[104px]`} src={require(`@/assets/image/${obj2.img}.jpg`)} alt=""/>
                          </div>
                          <div>
                            <div className={`font-[600] text-[16px] leading-[24px] text-white`}>
                              {obj2.title}
                            </div>
                            <div className={`line-clamp-3 mt-[8px] font-[300] text-[14px] leading-[24px] text-white`}>
                              {obj2.desc}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : SECTION-1 */}
      
      {/* 2023-01-09 :: START :: KSM : SECTION-2-MOBILE */}
      <section className={`ONLY-MOBILE bg-[var(--neutral-90)]
      pt-[35px] pb-[40px]
      `} data-seq="20230126160228457">
        <div className={`INSIDE`}>
          <div className={`Work-Sans font-[600] text-[12px] leading-[14px] text-white text-center`}>
            PROJECT IN PROGRESS
          </div>
          
          <div className={`mt-[20px] space-y-[25px]`}>
            {
              section_2_data.map((obj, idx) => {
                return (
                  <div key={idx} className={`text-center`}>
                    <div>
                      <img className={`w-full aspect-[350/231]`} src={require(`@/assets/image/${obj.img}.jpg`)} alt=""/>
                    </div>
                    <div>
                      <div className={`mt-[15px] font-[600] text-[14px] leading-[16px] text-white`}>
                        {obj.title}
                      </div>
                      <div className={`mt-[8px] font-[300] text-[14px] leading-[24px] text-white`}>
                        {toJsxStrArray(obj.desc)}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-09 :: END :: KSM : SECTION-2-MOBILE */}
      
      {/* 2023-01-19 :: START :: KSM : SECTION-3 */}
      <section className={`bg-[var(--neutral-90)]
      pt-[42px] pb-[29px]
      xl:pt-[134px] xl:pb-[175px]
      `} data-seq="20230126161053308">
        <div className={`INSIDE`}>
          <div className={`xl:grid xl:grid-cols-2 xl:gap-[0]`}>
            <div className={`font-[600] text-white
            text-[36px] leading-[44px] max-xl:text-center
            xl:text-[48px] xl:leading-[56px]
            `}>
              ‘생존’ 이 아닌<br/>
              ‘성장’ 을 말하십시오.<br/>
              최적의 Solution을<br/>
              제안해 드립니다.
            </div>
            <div className={`mt-[18px] max-xl:text-center`}>
              <div className={`ONLY-MOBILE font-[300] text-[16px] leading-[26px] text-white`}>
                국내 이커머스시장의 역사는 불과 20여년.<br/>
                많은 변화가 있었고 앞으로도 그럴 것입니다.<br/>
                누구 하나가 1등을 계속 할 수 없는 이커머스<br/>
                시장에서 내가 위치한 포지션에 따라<br/>
                성장의 전략은 무한합니다.
              </div>
              <div className={`ONLY-DESKTOP font-[400] text-[18px] leading-[26px] text-white`}>
                국내 이커머스시장의 역사는 불과 20여년.<br/>
                많은 변화가 있었고 앞으로도 그럴 것입니다.<br/>
                누구 하나가 1등을 계속 할 수 없는 이커머스 시장에서<br/>
                내가 위치한 포지션에 따라 성장의 전략은 무한합니다.
              </div>
            </div>
          </div>
          <div className={`
            mt-[45px] max-xl:space-y-[35px]
            xl:mt-[96px] xl:grid xl:grid-cols-3 xl:gap-[30px]
            `}>
            {
              section_3_data.map((obj, idx) => {
                return (
                  <Fragment key={idx}>
                    <div className={`max-xl:text-center`}>
                      <div>
                        <img className={`max-xl:mx-auto`} src={require(`@/assets/icon/${obj.icon}.svg`)} alt=""/>
                      </div>
                      <div className={`mt-[26px] font-[600] text-[16px] leading-[24px] text-white`}>
                        {toJsxStrArray(obj.title)}
                      </div>
                      <div className={`mt-[18px] font-[300] text-[14px] leading-[24px] text-white opacity-[.7]`}>
                        {toJsxStrArray(obj.desc)}
                      </div>
                    </div>
                  
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-19 :: END :: KSM : SECTION-3 */}
      
      {/* 2023-01-19 :: START :: KSM : SECTION-4-MOBILE */}
      <section className={`ONLY-MOBILE bg-[var(--neutral-90)] pt-[47px] pb-[30px]`}>
        <div className={`INSIDE`}>
          <div className={`font-[600] text-[36px] leading-[44px] text-white max-xl:text-center`}>
            귀사의 포지션은 <br/>
            어느단계 인가요?
          </div>
          <div className={`mt-[35px]`}>
            {
              section_4_data.map((obj1, idx1) => {
                return (
                  <Fragment key={idx1}>
                    <button className={`relative w-full text-left py-[24px] pl-[19px] pr-[32px] border-[1px] border-solid border-white/20 hover:border-white mt-[-1px]
                  `} onClick={() => {
                      dispatch(openInquiryLayer());
                    }}>
                      <div>
                        <div className={`font-[600] text-[20px] leading-[28px] text-white`}>
                          <div>
                            {obj1.title}
                          </div>
                        </div>
                        <div className={`mt-[8px] font-[300] text-[14px] leading-[24px] text-white`}>
                          {obj1.desc}
                        </div>
                      </div>
                      <div className={`z-10 absolute top-[26px] right-[9px]`}>
                        <img src={require(`@/assets/icon/icon-15.svg`).default} alt=""/>
                      </div>
                    </button>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-19 :: END :: KSM : SECTION-4-MOBILE */}
      
      {/* 2023-01-19 :: START :: KSM : SECTION-5-MOBILE */}
      <section className={`ONLY-MOBILE bg-[var(--neutral-90)] pt-[47px] pb-[35px]`} data-seq="20230126161153330">
        <div className={`INSIDE`}>
          <div className={`font-[600] text-[36px] leading-[44px] text-white max-xl:text-center`}>
            OUR<br/>
            EXPERIENCE
          </div>
          <div className={`mt-[12px] font-[300] text-[16px] leading-[26px] text-white max-xl:text-center`}>
            경험은 단순히 업력과 비례하지 않습니다.<br/>
            경험이 풍부하다는 것은 급변하는 시장 상황에 맞게<br/>
            즉각적으로 대응이 가능하다는 것을 의미합니다.
          </div>
          <div className={`mt-[47px] font-[600] text-[20px] leading-[28px] text-white text-center`}>
            {/*<div className={`border-t-[1px] border-t-white`}></div>*/}
            <div>
              E-Commerce Consulting
            </div>
          </div>
          <div className={`mt-[31px] space-y-[12px]`}>
            {
              section_5_data.map((obj, idx) => {
                return (
                  <Fragment key={idx}>
                    <div className={`bg-[#2B2B33] pt-[43px] pb-[37px] px-[43px] text-center`}>
                      <div>
                        <img className={`mx-auto`} src={require(`@/assets/icon/${obj.icon}.svg`)} alt=""/>
                      </div>
                      <div className={`mt-[41px] font-[400] text-[20px] leading-[30px] text-white`}>
                        {obj.title}
                      </div>
                      <div className={`mt-[19px] space-y-[10px] text-center`}>
                        {
                          obj.how.map((obj2, idx2) => {
                            return (
                              <Fragment key={idx2}>
                                <div className={`mx-auto leading-[44px] bg-white font-[700] text-[12px] max-w-[314px]`}>
                                  {obj2}
                                </div>
                              </Fragment>
                            );
                          })
                        }
                      </div>
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-19 :: END :: KSM : SECTION-5-MOBILE */}
      
      {/* 2023-01-19 :: START :: KSM : SECTION-6-MOBILE */}
      <section className={`ONLY-MOBILE bg-[var(--neutral-90)] pt-[47px] pb-[35px]`} data-seq="20230126121030694">
        <div className={`INSIDE`}>
          <div className={`font-[600] text-[36px] leading-[44px] text-white max-xl:text-center`}>
            ORGANIZATION
          </div>
          <div className={`mt-[17px] font-[300] text-[16px] leading-[26px] text-white max-xl:text-center`}>
            9개의 전문팀과 분야별 핵심 네트워크를 보유하고<br/>
            있어, 고객의 복합적인 니즈에 맞춘 토탈 서비스를<br/>
            적시에 제공합니다.
          </div>
          <div className={`mt-[35px]`}>
            <img className={`mx-auto`} src={require(`@/assets/image/img-15.png`)} alt=""/>
          </div>
        </div>
      </section>
      {/* // 2023-01-19 :: END :: KSM : SECTION-6-MOBILE */}
      
      {/* 2023-01-09 :: START :: KSM : SECTION-3-DESKTOP */}
      <section className={`ONLY-DESKTOP w-full`} data-seq="20230126121108020">
        <div id="hScrollWapper" className={`overflow-hidden bg-[var(--neutral-90)] h-[100vh] flex flex-row items-strech`}>
          <div className={`w-[var(--scrollbar-width)] grow-0 shrink-0`}>
            <div className={`h-full px-[165px] grid grid-cols-1 gap-[0] content-center`}>
              <div className={`font-[600] text-[48px] leading-[56px] text-white`}>
                귀사의 포지션은 <br/>
                어느단계 인가요?
              </div>
              <div className={`mt-[56px]`}>
                {
                  section_4_data.map((obj1, idx1) => {
                    return (
                      <Fragment key={idx1}>
                        <button className={`relative w-full text-left py-[24px] pl-[80px] pr-[calc(35px+24px)] border-[1px] border-solid border-white/20 hover:border-white mt-[-1px]
                  `} onClick={() => {
                          dispatch(openInquiryLayer());
                        }}>
                          <div>
                            <div className={`font-[600] text-[24px] leading-[36px] text-white`}>
                              <div>
                                {obj1.title}
                              </div>
                            </div>
                            <div className={`mt-[8px] font-[300] text-[14px] leading-[24px] text-white`}>
                              {toJsxStrArray(obj1.desc_2)}
                            </div>
                          </div>
                          <div className={`z-10 absolute top-[calc(50%-12px)] right-[35px]`}>
                            <img src={require(`@/assets/icon/icon-15.svg`).default} alt=""/>
                          </div>
                        </button>
                      </Fragment>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className={`w-[var(--scrollbar-width)] grow-0 shrink-0 EXPERIENCE-BOX`}>
            <div className={`h-full px-[165px] grid grid-cols-1 gap-[0] content-center`}>
              
              <div className={`grid grid-cols-2 gap-[0] `}>
                <div className={`font-[600] text-[48px] leading-[58px] text-white`}
                     data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
                >
                  OUR<br/>
                  EXPERIENCE
                </div>
                <div className={`font-[400] text-[18px] leading-[26px] text-white`}
                     data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}
                >
                  경험은 단순히 업력과 비례하지 않습니다.<br/>
                  경험이 풍부하다는 것은 급변하는 시장 상황에 맞게<br/>
                  즉각적으로 대응이 가능하다는 것을 의미합니다.
                </div>
              </div>
              <div className={`mt-[59px] font-[600] text-[20px] leading-[28px] text-white
                               grid grid-cols-[19px,1fr] gap-[10px] items-center
                             `}
                   data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 3}
              >
                <div className={`border-t-[1px] border-t-white`}></div>
                <div>
                  E-Commerce Consulting
                </div>
              </div>
              
              <div className={`mt-[18px] grid grid-cols-3 gap-[30px]`}>
                {
                  section_5_data.map((obj, idx) => {
                    return (
                      <Fragment key={idx}>
                        <div className={`bg-[#2B2B33] pt-[43px] pb-[37px] px-[43px]`}
                             data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * (idx + 4)}
                        >
                          <div>
                            <img src={require(`@/assets/icon/${obj.icon}.svg`)} alt=""/>
                          </div>
                          <div className={`mt-[41px] font-[400] text-[20px] leading-[30px] text-white`}>
                            {obj.title}
                          </div>
                          <div className={`mt-[19px] space-y-[10px] text-center`}>
                            {
                              obj.how.map((obj2, idx2) => {
                                return (
                                  <Fragment key={idx2}>
                                    <div className={`leading-[44px] bg-white font-[700] text-[12px]`}>
                                      {obj2}
                                    </div>
                                  </Fragment>
                                );
                              })
                            }
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className={`w-[var(--scrollbar-width)] grow-0 shrink-0`}>
            <div className={`h-full px-[165px] grid grid-cols-1 gap-[0] content-center`}>
              <div className={`grid grid-cols-2 gap-[0]`}>
                <div className={`font-[600] text-[48px] leading-[58px] text-white`}>
                  ORGANIZATION
                </div>
                <div className={`font-[400] text-[18px] leading-[26px] text-white`}>
                  9개의 전문팀과 분야별 핵심 네트워크를 보유하고 있어, 고객의 복합적인 니즈에 맞춘 토탈 서비스를 적시에 제공합니다.
                </div>
              </div>
              <div className={`mt-[107px]`}>
                <img src={require(`@/assets/image/img-15-pc.png`)} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // 2023-01-09 :: END :: KSM : SECTION-3-DESKTOP */}
      
      {/* 2023-01-19 :: START :: KSM : SECTION-7 */}
      <section className={`
      pt-[35px]
      xl:pt-[86px] xl:pb-[89px]
      `} data-seq="20230126121121752">
        <div className={`INSIDE`}>
          <div className={`font-[600] text-[var(--neutral-90)] max-xl:text-center
          text-[36px] leading-[44px]
          xl:text-[48px] xl:leading-[58px]
          `}>
            OUR WORK
          </div>
          <div className={`max-xl:text-center
          mt-[17px] 
          xl:mt-[60px] xl:grid xl:grid-cols-2 xl:gap-[31px]
          `}>
            <div className={`font-[600] text-[var(--neutral-90)]
           text-[24px] leading-[36px]
           xl:text-[36px] xl:leading-[44px]
          `}>
              커머스온은 컨설팅부터<br/>
              기획, 디자인, 개발, 운영까지<br/>
              A to Z Total Solution을<br/>
              제공합니다.
            </div>
            <div className={`max-xl:mt-[27px] max-xl:h-[140px]`}>
              <img className={`object-cover w-full h-full`} src={require(`@/assets/image/img-16.png`)} alt=""/>
            </div>
          </div>
          <div className={`
          mt-[15px] max-xl:space-y-[15px]
          xl:mt-[36px] xl:grid xl:grid-cols-2 xl:gap-[31px]
          `}>
            {
              section_7_data.map((obj1, idx1) => {
                return (
                  <Fragment key={idx1}>
                    <div className={`relative border-[1px] border-solid border-[#1A1814]/10 pt-[40px] pb-[40px] px-[24px] text-[var(--neutral-90)]
                    bg-[#F8F8F8] first:bg-[var(--neutral-90)] first:text-white
                    xl:even:top-[30px]`}>
                      <div className={`font-[600] text-[14px] leading-[16px]`}>
                        {obj1.title}
                      </div>
                      <div className={`mt-[15px] font-[300] text-[14px] leading-[24px]`}>
                        {obj1.desc}
                      </div>
                      <div className={`mt-[21px]
                      max-xl:space-y-[5px]
                      xl:inline-grid xl:grid-cols-[auto,auto] xl:gap-x-[40px] xl:gap-y-[5px]
                      `}>
                        {
                          obj1.desc_2.map((obj2, idx2) => {
                            return (
                              <Fragment key={idx2}>
                                <div className={`font-[400] text-[14px] leading-[24px]`}>
                                  {obj2}
                                </div>
                              </Fragment>
                            );
                          })
                        }
                      </div>
                    </div>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-19 :: END :: KSM : SECTION-7 */}
      
      {/* 2023-01-20 :: START :: KSM : SECTION-8 */}
      <section className={`
      pt-[48px] pb-[57px]
      xl:pt-[160px] xl:pb-[86px]
      `} data-seq="20230126121424667">
        <div className={`INSIDE`}>
          <div className={`xl:w-[1110px] max-xl:text-center xl:mx-auto xl:grid xl:grid-cols-[1fr,auto]`}>
            <div>
              <div className={`ONLY-MOBILE Work-Sans font-[600] text-[32px] leading-[42px] text-[var(--neutral-90)]`}>
                지난 십수 년간<br/>
                커머스에 특화된<br/>
                대기업, 중소/중견기업 등<br/>
                다양한 고객들의 비지니스<br/>
                성공에 조력해 왔습니다.
              </div>
              <div className={`ONLY-DESKTOP Work-Sans font-[600] text-[36px] leading-[44px] text-[var(--neutral-90)]`}>
                지난 십수 년간 이커머스에 특화된<br/>
                대기업, 중소/중견기업 등 다양한<br/>
                고객들의 비지니스 성공에 조력해 왔습니다.
              </div>
            </div>
            <div>
              <div className={`ONLY-MOBILE Work-Sans text-[var(--neutral-70)]
            max-xl:mt-[17px] font-[300] text-[16px] leading-[26px]
            `}>
                경험은 단순히 업력과 비례하지 않습니다.<br/>
                경험이 풍부하다는 것은 급변하는 시장상황에 맞게<br/>
                즉각적으로 대응이 가능하다는 것을 의미합니다.<br/>
                다양한 이커머스 플랫폼과 시스템 구축 경험, 그리고<br/>
                운영지원은 물론 미래를 함께 고민하는 브랜딩과<br/>
                컨설팅까지. 함께하는 길에 한계가 없습니다.
              </div>
              <div className={`ONLY-DESKTOP Work-Sans text-[var(--neutral-70)]
            font-[400] text-[16px] leading-[28px]
            `}>
                경험은 단순히 업력과 비례하지 않습니다.<br/>
                경험이 풍부하다는 것은 급변하는 시장상황에 맞게 즉각적으로<br/>
                대응이 가능하다는 것을 의미합니다. 다양한 이커머스 플랫폼과<br/>
                시스템 구축 경험, 그리고 운영지원은 물론 미래를 함께 고민하는<br/>
                브랜딩과 컨설팅까지. 함께하는 길에 한계가 없습니다.
              </div>
            </div>
          </div>
          <ul className={`grid
          mt-[37px] grid-cols-2 gap-x-[15px] gap-y-[30px]
          xl:w-[1110px] xl:mx-auto xl:mt-[72px] xl:grid-cols-4 xl:gap-x-[30px] xl:gap-y-[50px]
          `}>
            {
              Array.from(Array(16)).map((obj, idx) => {
                const img_num = (idx + 1).toString().padStart(2, '0');
                return (
                  <Fragment key={idx}>
                    <li>
                      <div className={`mx-auto w-[150px] h-[35px]`}>
                        <img className={`object-cover w-full h-full`} src={require(`@/assets/image/img-17-${img_num}.png`)} alt=""/>
                      </div>
                    </li>
                  </Fragment>
                );
              })
            }
          </ul>
          <div className={`mt-[60px] xl:mt-[76px] text-center`}>
            <Link to={'/?scroll=partnerListSec'} className={`inline-block BTN BTN-BLACK w-[220px]`}>
              파트너 포트폴리오 더보기
            </Link>
          </div>
        </div>
      </section>
      {/* // 2023-01-20 :: END :: KSM : SECTION-8 */}
    </div>
  );
}

export default PageConsulting;