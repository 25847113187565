const section_2_data = [
  {
    title : "TYPE A",
    img : "img-10-01",
  },
  {
    title : "TYPE B",
    img : "img-10-02",
  },
  {
    title : "TYPE C",
    img : "img-10-03",
  },
  {
    title : "TYPE D",
    img : "img-10-04",
  },
  {
    title : "TYPE E",
    img : "img-10-05",
  },
  {
    title : "상세페이지 A",
    img : "img-10-06",
  },
  {
    title : "상세페이지 B",
    img : "img-10-07",
  },
];

const section_3_data = [
  {
    title : "상품형",
    title_color : "#ffffff",
    title_bg : "#592827",
    desc : "많은 상품을 돋보이게 하는 디자인\n 깔끔한 디자인과 진열방식별 UI/UX\n 상품별 프로모션 구좌 배치를 통한 매출 증대 ",
    desc_2 : "상품형 디자인으로 상품량이 많고 카테고리별\n 상품이 다양한 브랜드에 적합한 디자인 다양한 \n 상품란 구성을 통해 보다 많은 상품을 보여주고\n 구매를 유도하는 디자인.",
    img : "img-11-01",
    img_bg : "img-13-01",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=349%3A12295&scaling=min-zoom&page-id=2%3A3",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=261%3A7411&scaling=min-zoom&page-id=2%3A3",
  },
  {
    title : "종합형",
    title_color : "1A1A1F",
    title_bg : "#ffffff",
    desc : "상품과 컨텐츠를 적절히 배합한 디자인\n 종합 쇼핑몰에 어울리는 다양한 기능과 구좌\n 기획전/이벤트/매거진/할인특가 구좌 배치",
    desc_2 : "종합형 디자인으로 백화점, 종합몰, 편집샵과 같은 브랜드에 적합한 디자인.\n 상품이 다양한 카테고리로 구분되어있고, 기획전/이벤트/매거진/할인특가 등\n 여러 구간을 활용해야할 시 적합한 디자인.",
    img : "img-11-02",
    img_bg : "img-13-02",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=349%3A11122&scaling=min-zoom&page-id=2%3A2",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=207%3A8232&scaling=min-zoom&page-id=2%3A2",
  },
  {
    title : "컨텐츠형",
    title_color : "#ffffff",
    title_bg : "#414232",
    desc : "브랜딩에 집중한 디자인\n 컨텐츠를 통해 상품을 돋보이게 하는 UI/UX\n 다양하고 주목성있는 컨텐츠가 많은 타입",
    desc_2 : "컨텐츠형 디자인으로 브랜드의 컨텐츠로\n 상품을 돋보여주는 디자인. \n 브랜드 아이덴티티를 담은 컨텐츠가 많은 \n 개인 브랜드에 적합한 시안.",
    img : "img-11-03",
    img_bg : "img-13-03",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=43%3A277&scaling=min-zoom&page-id=0%3A1",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=310%3A13581&scaling=min-zoom&page-id=0%3A1",
  },
  {
    title : "LNG 배너 상품형",
    title_color : "#1a1a1f",
    title_bg : "#f1ece7",
    desc : "심플한 레이아웃\n 메뉴를 좌측으로 배치하고 싶을 때\n 상품 및 이벤트/기획전 강조",
    desc_2 : "LNG 배너 상품형의 심플한 레이아웃으로\n 배너와 상품 영역에 집중한 디자인.\n 이벤트와 기획전 배너가 많고 상품을 강조해야하는 브랜드에 적합한 시안.",
    img : "img-11-04",
    img_bg : "img-13-04",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=175%3A7725&scaling=min-zoom&page-id=151%3A3665",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=350%3A14336&scaling=min-zoom&page-id=151%3A3665",
  },
];

const section_4_data = [
  {
    title : "리스트페이지 상품형",
    img : "img-12-01",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=119%3A5747&scaling=min-zoom&page-id=2%3A4",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=333%3A10640&scaling=min-zoom&page-id=2%3A4",
  },
  {
    title : "리스트페이지 배너형",
    img : "img-12-02",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=188%3A5313&scaling=min-zoom&page-id=2%3A5",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=333%3A10302&scaling=min-zoom&page-id=2%3A5",
  },
  {
    title : "상세페이지 기본형",
    img : "img-12-03",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=422%3A18924&scaling=min-zoom&page-id=2%3A6",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=393%3A11382&scaling=min-zoom&page-id=2%3A6",
  },
  {
    title : "상세페이지 스크롤형",
    img : "img-12-04",
    link_mobile : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=422%3A17844&scaling=min-zoom&page-id=377%3A16850",
    link_web : "https://www.figma.com/proto/BZHb8yFpWfxgiYMZJOe7lq/%EC%8A%A4%ED%82%A8%EB%94%94%EC%9E%90%EC%9D%B8?node-id=393%3A12542&scaling=min-zoom&page-id=377%3A16850",
  },
];

export {
  section_2_data,
  section_3_data,
  section_4_data,
}