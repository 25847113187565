const section_2_data = [
  {
    title : "고객중심 관점",
    icon : "icon-01",
    desc_MOBILE : "고객이 불편해할만한 요소들은\n 미리 제거합니다. 접속부터 전환까지\n 원활한 프로세스가 최우선입니다.",
    desc_DESKTOP : "고객이 불편해할만한 요소들은 미리 제거합니다.\n접속부터 전환까지 원활한 프로세스가 최우선입니다.",
  },
  {
    title : "스마트한 통합 관리기능",
    icon : "icon-02",
    desc_MOBILE : "십수 년간 실무자들이 직접 만들어\n 왔습니다.무엇이 운영의 비효율을\n 초래하는지 잘 알고 있습니다.",
    desc_DESKTOP : "십수 년간 실무자들이 직접 만들어 왔습니다.\n무엇이 운영의 비효율을 초래하는지 잘 알고 있습니다.",
  },
  {
    title : "마케팅 시너지",
    icon : "icon-03",
    desc_MOBILE : "다양한  제휴를 통해 시너지를\n 극대화 합니다. 마케팅 제휴는 물론,\n 데이터 분석 및 맞춤형 기능을 제공합니다.",
    desc_DESKTOP : "다양한  제휴를 통해 시너지를 극대화 합니다.\n 마케팅 제휴는 물론, 데이터 분석 및 맞춤형 기능을 제공합니다.",
  },
  {
    title : "원하는대로 커스텀",
    icon : "icon-04",
    desc_MOBILE : "ERP/WMS 유통 프로세스 연동\n 시스템으로 우리만의 비지니스에 특화된\n 기능을 제한없이 만들어 갑니다.",
    desc_DESKTOP : "ERP/WMS 유통 프로세스 연동 시스템으로\n 우리만의 비지니스에 특화된 기능을 제한없이 만들어 갑니다.",
  },
  {
    title : "안정적인 보안환경",
    icon : "icon-05",
    desc_MOBILE : "클라우드 기반 및 엄격한 보안기준에\n 따른 시스템 관리로 효율성과 안정성을\n 극대화 합니다.",
    desc_DESKTOP : "클라우드 기반 및 엄격한 보안기준에 따른 시스템 관리로\n 효율성과 안정성을 극대화 합니다.",
  },
]

const section_3_data = [
  {
    active : true,
    title : "이벤트가 갑자기\n 중단되었다구요?",
    desc_1_MOBILE : "클라우드 서버 환경의 시스템을 이용함으로써\n 물리(IDC) 서버의 크리티컬한 이슈들로부터\n 해방될 수 있습니다.",
    desc_1_DESKTOP : "클라우드 서버 환경의 시스템을 이용함으로써 물리(IDC)\n 서버의 크리티컬한 이슈들로부터 해방될 수 있습니다.",
    desc_2_MOBILE : "서버환경에 대한 제약조건도 없습니다.\n IDC 조건도 병행이 가능한 하이브리드\n 방식입니다. 클라우드를 기반으로한 보안 최적화되어 있습니다.",
    desc_2_DESKTOP : "서버환경에 대한 제약조건도 없습니다.\n IDC 조건도 병행이 가능한 하이브리드 방식입니다.\n 클라우드를 기반으로한 보안 최적화되어 있습니다.",
    img : "img-03-01",
  },
  {
    active : false,
    title : "하필, 제일 필요한\n 기능이 없다구요?",
    desc_1_MOBILE : "다양한 프로젝트를 통해 꼭 필요한 기능만 반영\n 하였습니다. 또한 고객사의 업무프로세스에 맞춰\n 기능을 원하는대로 구성해 드립니다.",
    desc_1_DESKTOP : "다양한 프로젝트를 통해 꼭 필요한 기능만 반영 하였습니다.\n 또한 고객사의 업무프로세스에 맞춰 기능을 원하는대로\n 구성해 드립니다.",
    desc_2_MOBILE : "꼭 필요한 기능으로 운영에 최적화 되어 있습니다.\n 타 쇼핑몰 대비 확장과 커스텀이 유용합니다.\n ERP, WMS, POS 등 필수 시스템 연동을 고려하여\n 코어를 구성하였습니다.",
    desc_2_DESKTOP : "꼭 필요한 기능으로 운영에 최적화 되어 있습니다.\n 타 쇼핑몰 대비 확장과 커스텀이 유용합니다. ERP, WMS, POS 등\n 필수 시스템 연동을 고려하여 코어를 구성하였습니다.",
    img : "img-03-02",
  },
  {
    active : false,
    title : "‘안된다’ 는 개발자의\n 말에 지치셨다구요?",
    desc_1_MOBILE : "운영을 해본 개발자는 함부로 안된다는 말을\n 하지 않습니다. 개발사의 두번째 실력은 바로\n ‘의지’ 입니다. 개발에 안되는 것은 없습니다.",
    desc_1_DESKTOP : "운영을 해본 개발자는 함부로 안된다는 말을 하지 않습니다.\n 개발사의 두번째 실력은 바로 ‘의지’ 입니다.\n 개발에 안되는 것은 없습니다.",
    desc_2_MOBILE : "개발사 이지만 현업 프로세스 분석 역량을 갖추고\n 있습니다. 유지보수 시 최적화된 대응 체계가 완비\n되어 있습니다. 빠르고 정확한 커뮤니케이션 프로\n세스를 갖추었습니다.",
    desc_2_DESKTOP : "개발사 이지만 현업 프로세스 분석 역량을 갖추고 있습니다.\n 유지보수 시 최적화된 대응 체계가 완비되어 있습니다.\n 빠르고 정확한 커뮤니케이션 프로세스를 갖추었습니다.",
    img : "img-03-03",
  },
  {
    active : false,
    title : "원하는 검색결과가\n 나오지 않는다구요?",
    desc_1_MOBILE : "커머스온은 자체 개발한 AI 검색엔진을 보유하고\n 있습니다. 월 수백만원 이상의 비용이 드는 첨단\n 기능을 무료로 이용하세요.",
    desc_1_DESKTOP : "커머스온은 자체 개발한 AI 검색엔진을 보유하고 있습니다.\n 월 수백만원 이상의 비용이 드는 첨단 기능을 무료로 이용하세요.",
    desc_2_MOBILE : "자체 검색엔진으로 제공됩니다.\n 대용량 데이터에 대응하기 위한 시스템\n 최적화를 지원 합니다. 상품리스트를 비롯한\n 시스템 전반에 적용됩니다.",
    desc_2_DESKTOP : "자체 검색엔진으로 제공됩니다.\n 대용량 데이터에 대응하기 위한 시스템 최적화를 지원 합니다.\n 상품리스트를 비롯한 시스템 전반에 적용됩니다.",
    img : "img-03-04",
  },
  {
    active : false,
    title : "고객마다 다른 경험을\n 주고 싶다구요?",
    desc_1_MOBILE : "방문 고객이 원하는 목적과 상품은 저마다\n 다릅니다. AI 추천 상품매칭 기능을 통해 방문\n 고객에게 새로움을 선사해주세요.",
    desc_1_DESKTOP : "방문 고객이 원하는 목적과 상품은 저마다 다릅니다. AI 추천\n 상품매칭 기능을 통해 방문 고객에게 새로움을 선사해주세요.",
    desc_2_MOBILE : "개인화 / 최적화를 위해 자체 개발한 AI 상품매칭\n 기능이 제공됩니다. 구축 단계 또는 유지보수를\n 통해 원하는 위치에 AI 추천 상품 노출이 가능합니다.",
    desc_2_DESKTOP : "개인화 / 최적화를 위해 자체 개발한 AI 상품매칭 기능이 제공됩니다.\n 구축 단계 또는 유지보수를 통해 원하는 위치에 AI 추천 상품 노출이 가능합니다.",
    img : "img-03-05",
  },
  {
    active : false,
    title : "최신 언어가 뭔지\n 잘 모르시겠다구요?",
    desc_1_MOBILE : "커머스온샵은 리액트(React) 를 기반으로 구축\n되었습니다. 쉽게 말해 이름만 들어도 아는 글로벌\n 기업들이 사용하는 개발언어와 스트럭처 입니다.",
    desc_1_DESKTOP : "커머스온샵은 리액트(React) 를 기반으로 구축되었습니다.\n 쉽게 말해 이름만 들어도 아는 글로벌 기업들이 사용하는\n 개발언어와 스트럭처 입니다.",
    desc_2_MOBILE : "리액트는 웹 어플리케이션의 UI를 효과적으로\n 구축하기 위해 사용하는 라이브러리 입니다.\n 성능 및 UI 수정과 재사용성, 모바일 대응\n 용이성이 뛰어납니다.",
    desc_2_DESKTOP : "리액트는 웹 어플리케이션의 UI를 효과적으로 구축하기 위해\n 사용하는 라이브러리 입니다.성능 및 UI 수정과 재사용성,\n 모바일 대응 용이성이 뛰어납니다.",
    img : "img-03-06",
  },
  {
    active : false,
    title : "제휴몰 연동이\n 번거로우시다구요?",
    desc_1_MOBILE : "제휴몰에 일일이 상품등록하랴, 재고 변경하랴,\n 주문수집하랴 손이 많이 가셨죠? 상품등록부터\n 주문수집, 클레임 관리 모두 자동으로 처리합니다.",
    desc_1_DESKTOP : "제휴몰에 일일이 상품등록하랴, 재고 변경하랴, 주문수집하랴\n 손이 많이 가셨죠? 상품등록부터 주문수집, 클레임 관리 모두\n 자동으로 처리합니다.",
    desc_2_MOBILE : "지정시간별 자동 주문, 클레임 수집이 가능합니다.\n 상품 카테고리 매칭 후 원스톱 등록기능이\n 탑재되어 있습니다. 주문 수집 에러 사항에 대한\n 실시간 모니터링이 가능합니다.",
    desc_2_DESKTOP : "지정시간별 자동 주문, 클레임 수집이 가능합니다.\n 상품 카테고리 매칭 후 원스톱 등록기능이 탑재되어 있습니다.\n 주문 수집 에러 사항에 대한 실시간 모니터링이 가능합니다.",
    img : "img-03-07",
  },
  {
    active : false,
    title : "CS 업무의 70%는\n 중복 설명?",
    desc_1_MOBILE : "고객의 대부분은 같은 내용을 묻습니다.\n 커머스온샵에서는 최대한 고객이 게시판을 통해\n 문의하고, 한번에 해결받을 수 있는 시스템이\n 반영 되어있습니다.",
    desc_1_DESKTOP : "고객의 대부분은 같은 내용을 묻습니다.\n 커머스온샵에서는 최대한 고객이 게시판을 통해 문의하고,\n 한번에 해결받을 수 있는 시스템이 반영 되어있습니다.",
    desc_2_MOBILE : "문의의 연속성을 위해 1:1 문의 연속달기 기능이\n 구현되어 있습니다. 답변에 대한 처리 진행상태를\n 3가지 타입으로 반영합니다.",
    desc_2_DESKTOP : "문의의 연속성을 위해 1:1 문의 연속달기 기능이 구현되어 있습니다.\n 답변에 대한 처리 진행상태를 3가지 타입으로 반영합니다.",
    img : "img-03-08",
  },
];

const section_4_data = [
  {
    title : "회원관리",
    desc : "CRM 관리\n 통합정보/활동이력 관리",
  },
  {
    title : "상품관리",
    desc : "통합상품관리\n 가격비교 시스템",
  },
  {
    title : "주문/배송 관리",
    desc : "통합주문관리\n 원스탑배송관리",
  },
  {
    title : "제휴 연동관리",
    desc : "오픈마켓 직접 연동\n 사방넷 연동",
  },
  {
    title : "이벤트 프로모션 관리",
    desc : "이벤트/프로모션 관리 \n 쿠폰관리",
  },
  {
    title : "디자인 관리",
    desc : "배너/팝업 진열 관리 \n 스킨관리",
  },
  {
    title : "통계 관리",
    desc : "기간별 매출, \n 회원 데이터 조회\n 기간대비 데이터 분석",
  },
  {
    title : "마케팅 관리",
    desc : "마케팅 채널 연동\n 가격비교 채널 연동",
  },
  {
    title : "공급사 관리",
    desc : "공급사 (입점사) 관리",
  },
  {
    title : "게시판 관리",
    desc : "리뷰 최적화 관리\n 문의글 최적화 관리",
  },
  {
    title : "설정관리",
    desc : "다양한 기능별 설정관리",
  },
  {
    title : "메인 대시보드 관리",
    desc : "주요 데이터 요약 열람",
  },
]

const section_5_data = [
  {
    title : "블랙야크",
    img : "img-04-01",
  },
  {
    title : "나우",
    img : "img-04-02",
  },
  {
    title : "힐크릭",
    img : "img-04-03",
  },
  {
    title : "파타고니아",
    img : "img-04-04",
  },
  {
    title : "넬슨스포츠",
    img : "img-04-05",
  },
  {
    title : "아크테릭스",
    img : "img-04-06",
  },
  {
    title : "코웰패션",
    img : "img-04-07",
  },
  {
    title : "에스키즈",
    img : "img-04-08",
  },
  {
    title : "애트니",
    img : "img-04-09",
  },
  {
    title : "오케이몰",
    img : "img-04-10",
  },
];

const section_6_data = [
  {
    title : "풍부한 개발인력",
    desc : "이커머스의 각 카테고리에서 풍부한 경험을 갖춘\n 개발인력으로 구성되어 있습니다. \n 또한 FPT그룹과의 파트너십을 통해 초대형 \n 프로젝트 까지도 수행이 가능합니다.",
    img : "img-05-01",
  },
  {
    title : "운영 경험 노하우",
    desc : "패션/스포츠/아웃도어에 특화된 이커머스 운영경험을 \n 통해 고객이 미처 알지 못하는 요소요소의 실무적 \n 어려움을 역제안하여 해결해 드립니다. ",
    img : "img-05-02",
  },
  {
    title : "다양한 개발 플랫폼",
    desc : "수십 건의 프로젝트를 수행하면서 갖추어진\n 자체 플랫폼을 통해 안정적인 서비스를 \n 제공하며, 언어에 구애 받지 않고 다양한 \n 개발 환경의 경험과 능력을 보유하고 있습니다. ",
    img : "img-05-03",
  },
  {
    title : "디자인 & 브랜딩 ",
    desc : "디자인 뿐만 아니라 해외 유수 기업의 브랜딩\n 프로젝트 수행 경험이 있어 보다 광범위한 \n 차원의 브랜딩 서비스 제공 및 컨설팅이 \n 가능합니다.",
    img : "img-05-04",
  },
];

export {
  section_2_data,
  section_3_data,
  section_4_data,
  section_5_data,
  section_6_data,
}