window.addEventListener("resize", (evt) => {
  init();
});

document.addEventListener('readystatechange', () => {
  infoScrollWidth();
});

function infoAppSize() {
  const el_html = document.querySelector('html');
  el_html.setAttribute(`data-app-wid`, window.innerWidth);
  el_html.setAttribute(`data-app-hei`, window.innerHeight);
}

function infoBreakPoint() {
  const el_html = document.querySelector('html');
  const wid = window.innerWidth;
  let prefix = '';
  if (wid < 768) prefix = 'sm';
  if (768 <= wid && wid < 1024) prefix = 'md';
  if (1024 <= wid && wid < 1280) prefix = 'lg';
  if (1280 <= wid && wid < 1536) prefix = 'xl';
  if (1536 <= wid) prefix = '2xl';
  el_html.setAttribute(`data-app-breakpoint`, prefix);
}

/**
 * 스크롤바 뺀 너비
 */
function infoScrollWidth(){
  const el_body = document.querySelector('body');
  const wid = el_body.clientWidth;
  
  const el_html = document.documentElement;
  el_html.style.setProperty('--scrollbar-width', wid + "px");
  el_html.setAttribute(`client-width`, wid);
}

function infoEnv(){
  const el_html = document.querySelector('html');
  
  for (let key in process.env) {
    let key2 = key.replace(/_/g, "-");
    key2 = key2.toLowerCase();
    el_html.setAttribute(`data-env-${key2}`, process.env[key]);
  }
}

function init() {
  infoAppSize();
  infoBreakPoint();
  infoScrollWidth();
}

function AppInfo({}) {
  init();
  
  if(process.env.NODE_ENV=="development") infoEnv();
  
  return null;
}

export default AppInfo;