import React, {Fragment, useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";

/* https://swiperjs.com/demos */
function PageSwiperjs({title}) {
  
  const myRefs = useRef();
  
  return (
    <div data-title={title} className={`PageSwiperjs p-5 bg-green-700`}>
      <section>
        <div className={`H4`}>Usage</div>
        <Swiper onSlideChange={() => console.log('onSlideChange')}
                onSwiper={(swiper) => {
                  myRefs.current = swiper;
                }}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
        <div className={`space-x-[10px] text-center mt-[5px]`}>
          <button className={`bg-white`} onClick={(evt) => {
            const swiper = myRefs.current;
            swiper.slidePrev();
          }}>PREV</button>
          <button className={`bg-white`} onClick={(evt) => {
            const swiper = myRefs.current;
            swiper.slideNext();
          }}>NEXT
          </button>
        </div>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination .my-pagination.type-1</div>
        <Swiper
          modules={[Pagination]}
          pagination={{
            el : '#my-pagination-1',
            type : 'bullets',
          }}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
        <div className={`relative`}>
          <div id={`my-pagination-1`} className={`my-pagination type-1`}></div>
        </div>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination .my-pagination.type-2</div>
        <Swiper
          modules={[Pagination]}
          pagination={{
            el : '#my-pagination-2',
            type : 'progressbar',
          }}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
        <div className={`relative`}>
          <div id={`my-pagination-2`} className={`my-pagination type-2`}></div>
        </div>
      </section>
      <br/>
      <hr/>
      <br/>
      <section>
        <div className={`H4`}>Default</div>
        <Swiper>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Navigation</div>
        <Swiper
          modules={[Navigation]}
          navigation={true}>
          {
            Array.from(Array(10)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination</div>
        <Swiper
          modules={[Pagination]}
          pagination={true}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination dynamicBullets</div>
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable : true,
            dynamicBullets : true,
          }}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination progress</div>
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{
            type : "progressbar",
          }}
          navigation={true}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination fraction</div>
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{
            type : "fraction",
          }}
          navigation={true}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Pagination custom</div>
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable : true,
            renderBullet : function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + "</span>";
            },
          }}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Slides per view</div>
        <Swiper
          modules={[Pagination]}
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable : true,
          }}
          className="mySwiper"
        >
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Slides per view auto</div>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          pagination={{
            clickable : true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {
            Array.from(Array(5)).map((obj, idx) => {
              const wid = 100 * (idx + 1);
              return (
                <SwiperSlide key={idx} style={{width : `${wid}px`}}>
                  <div className={`aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Infinite loop</div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable : true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      <br/>
      <section>
        <div className={`H4`}>Infinite loop with slides per group</div>
        <Swiper
          modules={[Pagination, Navigation]}
          slidesPerView={3}
          spaceBetween={30}
          slidesPerGroup={3}
          // loopFillGroupWithBlank={true}
          loop={true}
          pagination={{
            clickable : true,
          }}
          navigation={true}>
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
      
      <br/>
      <section>
        <div className={`H4`}>Autoplay</div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay : 2500,
            disableOnInteraction : false,
          }}
          pagination={{
            clickable : true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {
            Array.from(Array(5)).map((obj, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className={`w-full aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </section>
    </div>
  );
}

export default PageSwiperjs;