import {Link} from "react-router-dom";
function PageTemplate({title}) {
  return (
    <div data-title={title} className={`PageTemplate`}>
      <section>
        <div className={`INSIDE py-[20px]`}>
          <div className={`H1`}>.H1</div>
          <div className={`H2`}>.H2</div>
          <div className={`H3`}>.H3</div>
          <div className={`H4`}>.H4</div>
          <div className={`H5`}>.H5</div>
          <div className={`H6`}>.H6</div>
          <div className={`H7`}>.H7</div>
          <div className={`H8`}>.H8</div>
        </div>
      </section>
      <section className={`space-x-2 bg-[#838889]`}>
        <div className={`INSIDE py-[20px]`}>
          <div className={`H1`}>BTN</div>
          <Link className={`BTN BTN-PRIMARY`}>.BTN.BTN-PRIMARY</Link>
          <Link className={`BTN BTN-BLACK`}>.BTN.BTN-BLACK</Link>
          <Link className={`BTN BTN-WHITE`}>.BTN.BTN-WHITE</Link>
          <Link className={`BTN BTN-BLACK-O`}>.BTN.BTN-BLACK-O</Link>
          <Link className={`BTN BTN-WHITE-O`}>.BTN.BTN-WHITE-O</Link>
        </div>
      </section>
      <section className={`space-x-2 bg-[#838889]`}>
        <div className={`INSIDE py-[20px]`}>
          <div className={`H1`}>SELECT</div>
          <select className={`SELECT w-full mt-[27px]`}>
            <option>사이트구축 문의</option>
          </select>
        </div>
      </section>
      <section className={`space-x-2 bg-[#838889]`}>
        <div className={`INSIDE py-[20px]`}>
          <div className={`H1`}>INPUT_BOX</div>
          <div className={`INPUT_BOX`}>
            <label>EMAIL</label>
            <input type="email" defaultValue="anton@gamil.com"/>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PageTemplate;