import img_00 from "@/assets/image/img-00.png";
import img_00_pc from "@/assets/image/img-00-pc.png";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import {$, activeOneItem, Header, toJsxStrArray} from "@/util/util";
import {section_2_data, section_3_data, section_4_data, section_5_data, section_6_data} from "@/page/PageMain.data";
import _ from "lodash";
import {openInquiryLayer} from '@/lib/reduxToolkit/inquiry';
import {useDispatch} from 'react-redux';

function PageMain({title}) {
  Header.setLogoColor('white');
  
  const sec_3_refs = useRef([]);
  
  const us_sec_3_data = useState(section_3_data);
  const sec_3_data = us_sec_3_data[0];

  useEffect(() => {
    // console.log(sec_3_data);
    sec3AnimateRefresh();
  }, [sec_3_data]); // count가 바뀔 때만 effect를 재실행합니다.
  
  /**
   * DESKTOP 3번섹션 마우스 오버시 애니메이션 다시 재생
   */
  function sec3AnimateRefresh() {
    _.map(sec_3_refs.current, (anima_el) => {
      if (!$(anima_el).hasClass('aos-animate')) return;
      
      $(anima_el).removeClass('aos-animate');
      setTimeout(() => {
        $(anima_el).addClass('aos-animate');
      }, 300);
    });
  }

  const dispatch = useDispatch();
  
  return (
    <div data-title={title} className={`PageMain`}>
      {/* 2023-01-05 :: START :: KSM : SECTION-1 */}
      <section className={`relative
      max-xl:pt-[calc(36px+var(--header-height-MOBILE))] max-xl:pb-[28px] xl:pt-[calc(82px+var(--header-height-DESKTOP))] xl:pb-[121px]
      bg-cover bg-center bg-[url("@/assets/image/bg0.png")] xl:bg-[url("@/assets/image/bg0-pc.png")]
      `} data-seq="20230126150026640">
        {/*블라인드*/}
        <div className={`absolute top-0 left-0 z-10 bg-[#5A615D] w-full`}
             data-aos="blind-up" data-aos-offset="0" data-aos-delay={200 * 1}>
        </div>
        <div className={`INSIDE`}>
          <div className={`xl:grid xl:grid-cols-[49%,1fr] `}>
            {/*좌측 텍스트*/}
            <div>
              <div className={`text-white text-[36px] xl:text-[72px] font-[600] text-center xl:text-left leading-[44px] xl:leading-[76px]`}
                   data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}>
                답답해서<br/>
                만들었습니다<br/>
                비교해주세요
              </div>
              <div className={`mt-[14px] xl:mt-[32px] text-white font-[400] text-[14px] xl:text-[24px] leading-[20px] xl:leading-[36px] text-center xl:text-left`}
                   data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 3}>
                <div className={`ONLY-MOBILE`}>
                  겉보기에 똑같다고 모두 같은 쇼핑몰은 아닙니다.<br/>
                  카테고리, 상품군, 고객군 등에 따라 디테일한<br/>
                  요소들이 달라져 시스템에 잘 녹아들어야 합니다.<br/>
                  <br/>
                  커머스온은 시중에 존재하는<br/>
                  메이저 쇼핑몰의 장단점을 가장 잘 알고 있습니다.<br/>
                  패션/아웃도어에 특화된 무수한 프로젝트 경험을 바탕으로<br/>
                  이커머스에 최적화된 Total Solution을 만들었습니다.
                </div>
                <div className={`ONLY-DESKTOP`}>
                  겉보기에 똑같다고 모두 같은 쇼핑몰은 아닙니다.<br/>
                  카테고리, 상품군, 고객군 등에 따라 디테일한 요소들이<br/>
                  달라져 시스템에 잘 녹아들어야 합니다.<br/>
                  <br/>
                  커머스온은 시중에 존재하는<br/>
                  메이저 쇼핑몰의 장단점을 가장 잘 알고 있습니다.<br/>
                  패션/아웃도어에 특화된 무수한 프로젝트 경험을<br/>
                  바탕으로 이커머스에 최적화된 Total Solution을 만들었습니다.
                </div>
              </div>
            </div>
            {/*노트북*/}
            <div className={`mt-[34px] xl:mt-[0] text-center`}>
              <div>
                <img className={`ONLY-MOBILE mx-auto`} src={img_00} alt=""/>
              </div>
              <div>
                <img className={`ONLY-DESKTOP mx-auto mt-[86px] ml-[-33px]`} src={img_00_pc} alt=""
                     data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 4}
                />
              </div>
              <div className={`ONLY-MOBILE mt-[-19px]`}>
                <button className={`BTN BTN-WHITE w-full xl:w-[220px]`} onClick={() => {
                  dispatch(openInquiryLayer());
                }}>Contact
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : SECTION-1 */}
      
      {/* 2023-01-05 :: START :: KSM : SECTION-2 */}
      <section className={`SECTION-2 bg-[#5A615D] pt-[36px] xl:pt-[158px] pb-[44px] xl:pb-[157px]`} data-seq="20230126150033605">
        <div className={`INSIDE`}>
          <div className={`xl:grid xl:grid-cols-[630px,546px] xl:gap-[0] xl:justify-between`}>
            {/* 회색박스 */}
            {/*max-xl:mx-auto pt-[59px] pb-[59px] pl-[48px] text-[0] space-x-[6px] whitespace-nowrap overflow-hidden*/}
            <div className={`relative bg-[var(--neutral-10)]
            max-xl:mx-auto pt-[59px] pb-[59px] pl-[48px] text-[0] space-x-[1.56%] whitespace-nowrap overflow-hidden
            xl:pt-[204px] xl:pb-[0] xl:pl-[111px] xl:space-x-[10px] xl:w-[630px] xl:h-[735px]`}>
              {/*블라인드*/}
              <div className={`bg-[#5A615D] z-10 absolute top-[0] left-[0] w-full`}
                   data-aos="blind-up" data-aos-offset="0" data-aos-delay="400"
              ></div>
              
              {
                ["img-01", "img-02"].map((img, idx) => {
                  return (
                    <Fragment key={idx}>
                      <div className={`inline-block drop-shadow-[0_2px_20px_rgba(0,0,0,0.2)]
                      w-[51.56%] aspect-square
                      xl:w-[325px] xl:h-[326px]`}>
                        <img src={require(`@/assets/image/${img}.png`)} alt=""
                             data-aos="fade-left" data-aos-offset="0" data-aos-delay={200 * (idx + 2)}
                        />
                      </div>
                    </Fragment>
                  );
                })
              }
            </div>
            
            {/* 고객중심 관점... */}
            <div className={`mt-[30px] xl:mt-[0] space-y-[40px] xl:space-y-[45px]`}>
              {
                section_2_data.map((obj, idx) => {
                  
                  return (
                    <Fragment key={idx}>
                      {/* <!-- dd --> */}
                      <div className={`xl:grid xl:grid-cols-[auto,1fr] xl:gap-[30px]`}
                           data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * idx}
                      >
                        <div className={`xl:h-[44px] xl:flex xl:items-center`}>
                          <img className={`mx-auto xl:mx-0 w-[24px] xl:w-[32px] `} src={require(`@/assets/icon/${obj.icon}.svg`)} alt=""/>
                        </div>
                        <div className={`mt-[20px] xl:mt-[0] text-center xl:text-left text-white`}>
                          <div className={`font-[600] text-[24px] xl:text-[36px] leading-[36px] xl:leading-[44px]`}>
                            {obj.title}
                          </div>
                          <div className={`xl:mx-0 mt-[2px] xl:mt-[7px] font-[300] xl:font-[400] text-[16px] xl:text-[20px] leading-[26px] xl:leading-[30px] break-keep`}>
                            <div className={`ONLY-MOBILE`}>{toJsxStrArray(obj.desc_MOBILE)}</div>
                            <div className={`ONLY-DESKTOP`}>{toJsxStrArray(obj.desc_DESKTOP)}</div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              }
            </div>
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : SECTION-2 */}
      
      {/* 2023-01-05 :: START :: KSM : section 3 - MOBILE */}
      <section className={`ONLY-MOBILE bg-[#f5f5f5]`} data-seq="20230126150037930">
        <div className={`pt-[40px] pb-[53px] pl-[var(--app-contents-padding-MOBILE)]`}>
          <Swiper
            modules={[Pagination]}
            slidesPerView={"auto"}
            spaceBetween={27}
            loop={true}
            pagination={{
              el : '#my-pagination-23-01-03',
              type : 'progressbar',
            }}>
            {
              sec_3_data.map((obj, idx) => {
                return (
                  <SwiperSlide key={idx} className={'opacity-30 [&.swiper-slide-active]:opacity-100 !w-[272px] cursor-pointer select-none break-keep'}>
                    <div className={`font-[600] text-[32px] leading-[42px] text-center text-[var(--neutral-90)]`}>
                      {obj.title}
                    </div>
                    <div className={`mt-[23px] font-[300] text-[14px] leading-[24px] text-center text-[var(--neutral-90)]`}>
                      <div>
                        {toJsxStrArray(obj.desc_1_MOBILE)}
                      </div>
                      <br/>
                      <div>
                        {toJsxStrArray(obj.desc_2_MOBILE)}
                      </div>
                    </div>
                    <div className={`mt-[25px]`}>
                      <img src={require(`@/assets/image/${obj.img}.png`)} alt=""/>
                    </div>
                  </SwiperSlide>
                );
              })
            }
          </Swiper>
          <div className={`relative`}>
            <div id={`my-pagination-23-01-03`} className={`my-pagination type-2`}></div>
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : section 3 - MOBILE */}
      
      {/* 2023-01-05 :: START :: KSM : section 3 - DESKTOP */}
      <section className={`ONLY-DESKTOP bg-white pt-[100px] pb-[147px] `} data-seq="20230125184632339">
        <div className={`INSIDE`}>
          <div className={`grid grid-cols-[718px,1fr]`}>
            <div>
              <div ref={(item) => {
                sec_3_refs.current[0] = item;
              }} className={`font-[600] text-[48px] leading-[56px] text-[var(--neutral-90)] `}>
                {/* 좌측 */}
                {
                  sec_3_data.map((obj, idx) => {
                    return (
                      <Fragment key={idx}>
                        <div className={`hidden [&.ACTIVE]:block ${obj.active && 'ACTIVE'}`}>
                          {toJsxStrArray(obj.title)}
                        </div>
                      </Fragment>
                    );
                  })
                }
              </div>
              <div ref={(item) => {
                sec_3_refs.current[1] = item;
              }} className={`mt-[43px] font-[400] text-[24px] leading-[36px] text-[var(--neutral-90)]`}>
                {
                  sec_3_data.map((obj, idx) => {
                    return (
                      <Fragment key={idx}>
                        <div className={`hidden [&.ACTIVE]:block h-[calc(36px*7)] ${obj.active && 'ACTIVE'}`}>
                          <div>
                            {toJsxStrArray(obj.desc_1_DESKTOP)}
                          </div>
                          <br/>
                          <div>
                            {toJsxStrArray(obj.desc_2_DESKTOP)}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                }
              </div>
            </div>
            {/* 우측 */}
            <div>
              <div className={`space-y-[17px]`}>
                {
                  sec_3_data.map((obj, idx) => {
                    return (
                      <Fragment key={idx}>
                        <div className={`cursor-pointer block relative ${obj.active && 'ACTIVE'}`} onMouseEnter={() => {
                          activeOneItem(us_sec_3_data, obj);
                        }}>
                          <div className={`inline-block
                          font-[600] text-[32px] leading-[42px] text-[var(--neutral-30)] border-b-[4px] border-b-white/0
                          [.ACTIVE_&]:text-[var(--neutral-90)] [.ACTIVE_&]:border-b-[var(--neutral-90)]
                          `}>
                            {obj.title}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })
                }
              </div>
            </div>
          </div>
          {/* 하단 이미지 */}
          <div className={`mt-[74px]`} ref={(item) => {
            sec_3_refs.current[2] = item;
          }}>
            {
              sec_3_data.map((obj, idx) => {
                return (
                  <Fragment key={idx}>
                    <img data-idx={idx} src={require(`@/assets/image/${obj.img}.jpg`)} alt="" className={`hidden [&.ACTIVE]:block ${obj.active && 'ACTIVE'}`}/>
                  </Fragment>
                );
              })
            }
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : section 3 - DESKTOP */}
      
      {/* 2023-01-04 :: START :: KSM : section 4 */}
      <section className={`bg-[#F0E3D0]
      pt-[31px] pb-[63px]
      xl:pt-[81px] xl:pb-[114px]`} data-seq="20230125191100630">
        <div className={`INSIDE`}>
          <div>
            <div className={`ONLY-MOBILE font-[600] text-center text-[var(--neutral-90)] text-[36px] leading-[44px] `}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >
              커머스온은<br/>
              이렇게 다릅니다<br/>
              세부기능 알아보기
            </div>
            <div className={`ONLY-DESKTOP font-[600] text-center text-[var(--neutral-90)] text-[48px] leading-[56px] `}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >
              커머스온은 이렇게 다릅니다<br/>
              세부기능 알아보기
            </div>
          </div>
          <div>
            <div className={`ONLY-MOBILE max-xl:max-w-[325px] mx-auto text-center text-[var(--neutral-90)] mt-[17px] font-[300] text-[14px] leading-[24px] `}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >
              각 세부 메뉴별로 판매와 운영에 필요한 모든 기능을<br/>
              담았습니다. 각 기능들은 클릭 한 번을 더 줄여주기 위해<br/>
              수많은 파트너사의 실무자들과 고민을 거듭한<br/>
              결과물 입니다. 커머스온샵 만의 차별화 된 다양한<br/>
              기능들을 만나보세요.
            </div>
            <div className={`ONLY-DESKTOP max-xl:max-w-[325px] mx-auto text-center text-[var(--neutral-90)] mt-[27px] font-[400] text-[24px] leading-[36px]`}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >
              각 세부 메뉴별로 판매와 운영에 필요한 모든 기능을 담았습니다.<br/>
              각 기능들은 클릭 한 번을 더 줄여주기 위해 수많은 파트너사의 실무자들과 고민을 거듭한 결과물 입니다.<br/>
              커머스온샵 만의 차별화 된 다양한 기능들을 만나보세요.
            </div>
          </div>
          <ul className={`mt-[22px] xl:mt-[58px] grid justify-center
          grid-cols-[repeat(2,1fr)] gap-[2px] auto-rows-[125px]
          xl:grid-cols-[repeat(4,293px)] xl:gap-[3px] xl:auto-rows-[152px]`}>
            {
              section_4_data.map((obj, idx) => {
                return (
                  <Fragment key={idx}>
                    <li className={` text-white bg-[#A19992]
                    pt-[10px] pl-[13px]
                    xl:pt-[24px] xl:pl-[28px]`}
                        data-aos="fade-up" data-aos-offset="0" data-aos-delay={(200 * (idx + 1))}
                    >
                      <div className={`font-[600] text-[16px] leading-[24px]`}>{obj.title}</div>
                      <div className={`mt-[15px] font-[500] text-[14px]
                      leading-[20px]
                      xl:leading-[16px]`}>{toJsxStrArray(obj.desc)}</div>
                    </li>
                  </Fragment>
                );
              })
            }
          </ul>
          
          <div className={`text-center
          mt-[40px]
          xl:mt-[80px]`}>
            <Link to="/PageIntroduction" className={`BTN BTN-BLACK w-[169px]`}
                  data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >기능 자세히보기</Link>
          </div>
        </div>
      </section>
      {/* // 2023-01-04 :: END :: KSM : section 4 */}
      
      {/* 2023-01-05 :: START :: KSM : section 5 */}
      <section id="partnerListSec" className={`bg-[#966D67] overflow-hidden
      pt-[40px] pb-[60px]
      xl:pt-[83px] xl:pb-[129px]
      `} data-seq="20230125191113469">
        <div className={`INSIDE`}>
          <div data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
            <div className={`ONLY-MOBILE font-[600] text-[36px] leading-[44px] text-center text-white`}>
              커머스온과<br/>
              함께 했습니다<br/>
              파트너사 둘러보기
            </div>
            <div className={`ONLY-DESKTOP font-[600] text-[48px] leading-[56px] text-white`}>
              커머스온과 함께 했습니다<br/>
              파트너사 둘러보기
            </div>
          </div>
        </div>
        {/*MOBILE 슬라이드*/}
        <div className={`ONLY-MOBILE overflow-hidden
        mt-[37px]
        xl:mt-[64px]
        [&_.swiper-wrapper]:ease-linear
        `}>
          <div>
            <Swiper
              modules={[Autoplay]}
              slidesPerView={"auto"}
              spaceBetween={5}
              autoplay={{
                delay : 0,
              }}
              speed={3000}
              loop={true}>
              {
                section_5_data.slice(0, 4).map((obj, idx) => {
                  return (
                    <SwiperSlide key={idx} className={`!w-[197px]`} style={{"transitionTimingFunction": "linear"}}>
                      {/*<div className={`aspect-[16/9] flex items-center justify-center text-[30px] bg-amber-300`}>Slide {idx}</div>*/}
                      <div>
                        <img src={require(`@/assets/image/${obj.img}.jpg`)} alt=""/>
                      </div>
                      <div className={`mt-[2px]`}>
                        <span className={`font-[400] text-[12px] leading-[26px] text-white border-b-[1px] border-b-white`}>
                          {obj.title}
                        </span>
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
          <div className={`mt-[16px]`}>
            <Swiper
              modules={[Autoplay]}
              slidesPerView={"auto"}
              spaceBetween={5}
              autoplay={{
                delay : 0,
                reverseDirection : true,
              }}
              speed={3000}
              loop={true}>
              {
                section_5_data.slice(5, 9).map((obj, idx) => {
                  return (
                    <SwiperSlide key={idx} className={`!w-[197px]`}>
                      <div>
                        <img src={require(`@/assets/image/${obj.img}.jpg`)} alt=""/>
                      </div>
                      <div className={`mt-[2px]`}>
                        <span className={`font-[700] text-[12px] leading-[26px] text-white border-b-[1px] border-b-white`}>
                          {obj.title}
                        </span>
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </div>
        
        {/*DESKTOP 슬라이드*/}
        <div className={`ONLY-DESKTOP mt-[40px] pl-[calc((var(--scrollbar-width)-var(--app-min-width-DESKTOP))/2)]`}>
          <div>
            <Swiper className={`SWIPER-1`}
                    modules={[Autoplay, Pagination, Navigation]}
                    pagination={{
                      el : '#pagination-23-01-10',
                      type : "fraction",
                    }}
                    navigation={{
                      enabled : true,
                      nextEl : '#navigation-next-23-01-10',
                      prevEl : '#navigation-prev-23-01-10',
                    }}
                    slidesPerView={"auto"}
                    spaceBetween={5}
                    autoplay={{
                      delay : 25000,
                    }}
                    loop={true}>
              {
                section_5_data.map((obj, idx) => {
                  return (
                    <SwiperSlide key={idx} className={`!w-[450px]`}>
                      <div>
                        <img src={require(`@/assets/image/${obj.img}.jpg`)} alt=""/>
                      </div>
                      <div className={`mt-[2px]`}>
                        <span className={`TITLE font-[700] text-[12px] leading-[26px] text-white border-b-[1px] border-b-white`}>
                          {obj.title}
                        </span>
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
          <div className={`relative z-10 mt-[48px] grid grid-cols-[50px,60px] gap-[7px] items-center justify-start`}>
            <div className={`relative text-center`}>
              <div id="pagination-23-01-10" className={`my-pagination type-3`}></div>
            </div>
            <div className={`grid grid-cols-2 gap-[0]`}>
              <div id="navigation-prev-23-01-10" className={`my-navigation prev type-1`}>PREV</div>
              <div id="navigation-next-23-01-10" className={`my-navigation next type-1`}>NEXT</div>
            </div>
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : section 5 */}
      
      {/* 2023-01-05 :: START :: KSM : section 6 */}
      <section className={`bg-[#EFEFEF] pt-[34px] pb-[54px] xl:pt-[79px] xl:pb-[135px]`} data-seq="20230125191124047">
        <div className={`INSIDE`}>
          <div className={`xl:grid xl:grid-cols-2 xl:gap-[0]`}>
            {/*좌측 상단 텍스트*/}
            <div className={`font-[600] text-[36px] xl:text-[48px] leading-[44px] xl:leading-[58px] text-[var(--neutral-90)] text-center xl:text-left`}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}
            >
              OUR
              <br/>
              STRENGTH
            </div>
            {/*우측 상단 텍스트*/}
            <div className={`text-[var(--neutral-90)]
            max-xl:mx-auto max-xl:mt-[16px] max-xl:text-center
            font-[300] text-[14px] leading-[24px]
            xl:font-[400] xl:text-[24px] xl:leading-[36px]
            `}
                 data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * 2}
            >
              <div className={`ONLY-MOBILE`}>
                지난 십수 년간 이커머스에 특화된 대기업, 중소/중견기업<br/>
                등 다양한 고객과 함께 사이트 구축 및 물류시스템 개발,<br/>
                연동, 운영, 관리, 컨설팅을 수행해 왔습니다.
              </div>
              <div className={`ONLY-DESKTOP`}>
                지난 십수 년간 이커머스에 특화된 대기업, 중소/중견기업<br/>
                등 다양한 고객과 함께 사이트 구축 및 물류시스템 개발,<br/>
                연동, 운영, 관리, 컨설팅을 수행해 왔습니다.
              </div>
            </div>
          </div>
          {/*이미지 리스트*/}
          <ul className={`mt-[38px] xl:mt-[52px] mx-auto space-y-[30px] xl:space-y-[0] xl:grid xl:grid-cols-4 xl:gap-[30px]`}>
            {
              section_6_data.map((obj, idx) => {
                return (
                  <Fragment key={idx}>
                    <li className={`bg-white`}
                        data-aos="fade-up" data-aos-offset="0" data-aos-delay={200 * (idx + 1)}
                    >
                      <div>
                        <img className={`w-full`} src={require(`@/assets/image/${obj.img}.png`)} alt=""/>
                      </div>
                      <div className={`
                      pt-[40px] max-xl:pb-[45px]
                      xl:pt-[30px] xl:h-[227px] xl:px-[32px]
                      `}>
                        <div className={`font-[600] text-[16px] leading-[24px] text-[var(--neutral-90)]
                        max-xl:text-center`}>
                          {obj.title}
                        </div>
                        <div className={`mt-[12px] font-[300] text-[14px] leading-[24px] text-[var(--neutral-90)]
                        max-xl:text-center`}>
                          <div className={`xl:[&_br]:hidden`}>
                            {toJsxStrArray(obj.desc)}
                          </div>
                        </div>
                      </div>
                    </li>
                  </Fragment>
                );
              })
            }
          </ul>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : section 6 */}
      
      {/* 2023-01-05 :: START :: KSM : section 7 */}
      <section className={`bg-[#878270]
      pt-[37px] pb-[59px]
      xl:pt-[37px] xl:pb-[105px]
      `} data-seq="20230125191129260">
        <div className={`INSIDE`}>
          <div className={`xl:grid xl:grid-cols-[auto,635px] xl:gap-[0]`}>
            <div className={`xl:pt-[79px]`}>
              <div className={`font-[600] text-[36px] xl:text-[48px] leading-[44px] xl:leading-[58px] text-white text-center xl:text-left`}>
                도움이 필요하다면?
                <br/>
                이커머스에 관한
                <br/>
                모든것을 문의하세요.
              </div>
              <div className={`mt-[18px] xl:mt-[32px] font-[300] xl:font-[400] text-[14px] xl:text-[18px] leading-[24px] xl:leading-[26px] text-white text-center xl:text-left`}>
                커머스온은 이커머스분야에서 고객사의 꿈을
                <br/>
                실현시켜주는 이상을 가지고 있습니다. 홈페이지 제작
                <br/>
                뿐만 아니라 시스템 기획, 구축, 통합, 디자인, 컨설팅,
                <br/>
                솔루션 전반에 걸친 커머스 종합 솔루션을 제공합니다.
              </div>
            </div>
            <ul className={`mt-[11px] xl:mt-[0]`}>
              <li className={`border-b-solid border-b-[1px] border-b-white/10`} data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
                <button className={`block w-full grid grid-cols-[43px,1fr,50px] gap-[0] items-center h-[102px] xl:h-[156px]`}
                        onClick={() => {
                          dispatch(openInquiryLayer({type: 'build-site'}));
                        }}
                >
                  <div className={` font-[400] text-[12px] leading-[16px] text-white text-left`}>
                    01
                  </div>
                  <div className={` font-[600] text-[24px] leading-[36px] text-white text-left`}>
                    프로젝트 문의
                  </div>
                  <div>
                    <img src={require(`@/assets/icon/icon-06.svg`).default} alt=""/>
                  </div>
                </button>
              </li>
              <li className={`border-b-solid border-b-[1px] border-b-white/10`} data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
                <button className={`block w-full grid grid-cols-[43px,1fr,50px] gap-[0] items-center h-[102px] xl:h-[156px]`}
                        onClick={() => {
                          dispatch(openInquiryLayer({type: "operation"}));
                        }}
                >
                  <div className={` font-[400] text-[12px] leading-[16px] text-white text-left`}>
                    02
                  </div>
                  <div className={` font-[600] text-[24px] leading-[36px] text-white text-left`}>
                    운영/컨설팅 문의
                  </div>
                  <div>
                    <img src={require(`@/assets/icon/icon-06.svg`).default} alt=""/>
                  </div>
                </button>
              </li>
              <li className={`border-b-solid border-b-[1px] border-b-white/10`} data-aos="fade-up" data-aos-offset="0" data-aos-delay={200}>
                <Link to={`/PageCustomerService`} className={`block w-full grid grid-cols-[43px,1fr,50px] gap-[0] items-center h-[102px] xl:h-[156px]`}>
                  <div className={` font-[400] text-[12px] leading-[16px] text-white text-left`}>
                    03
                  </div>
                  <div className={` font-[600] text-[24px] leading-[36px] text-white text-left`}>
                    솔루션 관련 문의
                  </div>
                  <div>
                    <img src={require(`@/assets/icon/icon-06.svg`).default} alt=""/>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* // 2023-01-05 :: END :: KSM : section 7 */}
    </div>
  );
}

export default PageMain;