const EMAIL_JS = {
  SERVICE_ID: "service_vtty8jr",
  TEMPLATE_ID: {
    PROJECT_INQUIRY: "template_d6bv2vm",
    GENERAL_INQUIRY: "template_juy0iog"
  },
  PUBLIC_KEY: "wlcTLNx_Sgaqk-gnb"
};

export const ENV = {
  EMAIL_JS
};
