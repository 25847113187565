import {Fragment, useEffect} from "react";
import {Header} from "@/util/util";
function PagePersonalDataPolicy({title}) {
  Header.setLogoColor('white');
  
  return (
    <div data-title={title} className={`PagePersonalDataPolicy`}>
      {/* 2023-01-16 :: START :: KSM : SECTION-1 */}
      <section className={`bg-[var(--neutral-90)]
      pt-[117px] pb-[34px]
      xl:pt-[144px] xl:pb-[25px]
      `}>
        <div className={`INSIDE`}>
          <div className={`text-white
          max-xl:text-center
          font-[500] text-[36px] leading-[44px]
          xl:font-[400] xl:text-[48px] xl:leading-[56px]
          `}>개인정보 처리방침</div>
        </div>
      </section>
      {/* // 2023-01-16 :: END :: KSM : SECTION-1 */}
  
      {/* 2023-01-19 :: START :: KSM : SECTION-2 */}
      <section className={`
      pt-[15px] pb-[40px]
      xl:pt-[21px] xl:pb-[90px]
      `}>
        <div className={`INSIDE C-TEXT-1`}>
          <div>
            (주)커머스온 (이하 “회사”)은 「개인정보 보호법」
            제30조에 따라 정보주체의 개인정보를 보호하고
            이와 관련한 고충을 신속하고 원활하게 처리할 수
            있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. 이 개인정보처리방침은
            2022년 1월 1일부터 적용됩니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제1조(개인정보의 처리 목적)</div>
          <br/>
          <div>
            (주)커머스온(이하 “회사”)는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」
            제18조에 따라 별도의 동의를 받는 등 필요한 조치를
            이행할 예정입니다.
          </div>
          <br/>
          <div>
            서비스 의뢰 문의
          </div>
          <div>
            – 원활한 상담 진행 및 서비스 제공을 위한 목적으로 개인정보를 수집합니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제2조(개인정보의 처리 및 보유 기간)</div>
          <br/>
          <div>
            회사는 법령에 따른 개인정보 보유·이용기간 또는
            정보주체로부터 개인정보를 수집 시에 동의 받은
            개인정보 보유·이용기간 내에서 개인정보를 처리·
            보유합니다. 개인정보 처리 및 보유 기간은 다음과 같습니다.
          </div>
          <br/>
          <div>서비스 의뢰 문의</div>
          <div>
            - 개인정보는 상담 답변 후 지체없이 파기합니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제3조(처리하는 개인정보의 항목 및 수집 방법)</div>
          <br/>
          <div>
            회사는 원활한 상담 및 서비스 제공을 위해 아래와 같이 고객의 개인정보를 수집하고 있습니다.
          </div>
          <div>
            1. 서비스 의뢰 문의
          </div>
          <div>
            – 담당자 명, 소속, 연락처, 이메일, 예산 범위, 의뢰내용
          </div>
          <div>
            2.서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
          </div>
          <div>
            – 시스템 접근 로그, 쿠키
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제4조(개인정보의 제3자 제공에 관한 사항)</div>
          <br/>
          <div>
            회사는 원칙적으로 이용자의 개인정보를 제1조
            (개인정보의 처리 목적)에서 명시한 범위 내에서
            처리하며, 이용자의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제5조(개인정보의 파기)</div>
          <br/>
          <div>
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이
            해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </div>
          <br/>
          <div className={`C-STRONG-1`}>1. 파기절차</div>
          <div>
            회사는 파기 사유가 발생한 개인정보를 선정하고,
            회사의 개인정보 보호책임자의 승인을 받아
            개인정보를 파기합니다.
          </div>
          <div className={`C-STRONG-1`}>2. 파기방법</div>
          <div>
            회사에서 처리하는 개인정보파일은 전자적 파일
            형태로 기록되기 때문에 재생할 수 없는 기술적
            방법을 사용하여 파기합니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제6조(개인정보의 안전성 확보 조치)</div>
          <br/>
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
          <br/>
          <div>
            1) 개인정보 취급 직원의 최소화 및 교육
            개인정보를 취급하는 직원을 지정하고 담당자에
            한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
          </div>
          <div>
            2) 내부관리계획의 수립 및 시행
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
          </div>
          <div>
            3) 해킹 등에 대비한 기술적 대책
            회사는 해킹이나 컴퓨터 바이러스 등에 의한
            개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/
            물리적으로 감시 및 차단하고 있습니다.
          </div>
          <div>
            4) 문서보안을 위한 잠금장치 사용
            개인정보가 포함된 서류, 보조저장매체 등을
            잠금장치가 있는 안전한 장소에 보관하고 있습니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제6조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</div>
          <br/>
          <div>
            회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
          </div>
          <div>
            쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의
            정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
          </div>
          <br/>
          <div>
            <span className={`C-STRONG-1`}>가.</span> 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
          </div>
          <div>
            <span className={`C-STRONG-1`}>나.</span> 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
          </div>
          <div>
            <span className={`C-STRONG-1`}>다.</span> 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제7조(개인정보 보호책임자)</div>
          <br/>
          <div>
            회사는 개인정보 처리에 관한 업무를 총괄해서
            책임지고, 개인정보 처리와 관련한 정보주체의
            불만처리 및 피해구제 등을 위하여 아래와 같이
            개인정보 보호책임자를 지정하고 있습니다.
          </div>
          <br/>
          <div>
            개인정보 보호책임자
          </div>
          <div>
            성명: 윤석호
          </div>
          <div>
            이메일: frostysh@commerceon.co.kr
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제8조 (개인정보 열람청구)</div>
          <br/>
          <div>
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른
            개인정보의 열람 청구를 아래 담당자에게 할 수
            있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
          </div>
          <br/>
          <div>
            개인정보 열람청구 접수·처리자
          </div>
          <div>
            성명: 윤석호
          </div>
          <div>
            이메일: frostysh@commerceon.co.kr
          </div>
          <br/>
          <div className={`C-TITLE-1`}>제9조 (권익침해 구제방법)</div>
          <br/>
          <div>
            정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
            개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
            바랍니다.
          </div>
          <br/>
          <div>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</div>
          <div>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</div>
          <div>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</div>
          <div>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</div>
          <div>
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의
            처리정지 등)의 규정에 의한 요구에 대 하여
            공공기관의 장이 행한 처분 또는 부작위로 인하여
            권리 또는 이익의 침해를 받은 자는 행정심판법이
            정하는 바에 따라 행정심판을 청구할 수 있습니다.
          </div>
          <br/>
          <div>
            ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)
            홈페이지를 참고하시기 바랍니다.
          </div>
        </div>
      </section>
      {/* // 2023-01-19 :: END :: KSM : SECTION-2 */}
    </div>
  );
}

export default PagePersonalDataPolicy;