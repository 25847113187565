import React from "react";

const $ = (target) => {
  if (typeof target === 'string') {
    target = document.querySelector(target);
  }
  
  /**
   * 이벤트 해제
   * @param type_namespace
   */
  target.off = (event_type, namespace) => {
    if (!namespace) {
      throw new Error('namespace 가 반드시 지정되어야합니다.');
    }
    const handler_name = `handler_${event_type}${(namespace) ? "_" + namespace : ""}`;
    
    target.removeEventListener(event_type, target[handler_name]);
    target[handler_name] = undefined;
  }
  
  /**
   * 이벤트 등록
   * @param type_namespace
   * @param callback
   */
  target.on = (event_type, namespace, callback) => {
    if (!namespace) {
      throw new Error('namespace 가 반드시 지정되어야합니다.');
    }
    const handler_name = `handler_${event_type}${(namespace) ? "_" + namespace : ""}`;
    
    target.off(event_type, namespace);
    target[handler_name] = callback;
    target.addEventListener(event_type, callback);
  }
  
  /**
   * 형제 요소 반환
   * @returns {*[]}
   */
  target.siblings = () => {
    let result = [];
    const data = target.parentElement.children;
    let len = data.length;
    for (let i = 0; i < len; i++) {
      const $item = data[i];
      if (target != $item) {
        result.push($item);
      }
    }
    
    return result;
  }
  
  /**
   *
   * @param evt
   */
  target.addClass = (class_name) => {
    const len = target.length;
    if (target.length) {
      for (let i = 0; i < len; i++) {
        target[i].classList.add(class_name);
      }
    } else {
      target.classList.add(class_name);
    }
  }
  
  /**
   *
   * @param evt
   */
  target.removeClass = (class_name) => {
    const len = target.length;
    if (target.length) {
      for (let i = 0; i < len; i++) {
        target[i].classList.remove(class_name);
      }
    } else {
      target.classList.remove(class_name);
    }
  }
  
  /**
   *
   * @param evt
   */
  target.hasClass = (class_name) => {
    return target.classList.contains(class_name);
  }
  
  /**
   * ACTIVE 클래스 추가
   * @constructor
   */
  target.ACTIVE = () => {
    target.addClass('ACTIVE');
  }
  
  /**
   * ACTIVE 클래스 제거
   * @constructor
   */
  target.INACTIVE = () => {
    target.removeClass('ACTIVE');
  }
  
  return target;
}

const Header = {};

/**
 *
 * @param color_string : light, dark
 */
Header.setLogoColor = function (color_string) {
  const el_html = document.querySelector('html');
  el_html.setAttribute('data-header-logo', color_string);
}

/**
 * \n 을 <br/> 오브젝트로 변환해서 배열에 담아줌
 * @param string
 * @returns {*[]}
 */
function toJsxStrArray(string) {
  if (!string) return;
  let result = [];
  const ori_arr = string.split('\n');
  ori_arr.forEach((el, index) => {
    result.push(el);
    if (ori_arr.length - 1 > index) result.push(<br key={index}/>);
  });
  return result;
}

/**
 * 하나만 active
 * @param use_state_var
 * @param item_obj
 */
function activeOneItem(use_state_var, item_obj) {
  // .aos-animate
  const us_getter = use_state_var[0];
  const us_setter = use_state_var[1];
  
  us_getter.forEach((_obj, _idx) => {
    if (item_obj == _obj) {
      _obj.active = true;
    } else {
      _obj.active = false;
    }
  });
  
  us_setter([...us_getter]);
}

/**
 * 모든 active 제거
 */
function inactiveAllItem(use_state_var) {
  const us_getter = use_state_var[0];
  const us_setter = use_state_var[1];
  
  us_getter.forEach((_obj, _idx) => {
    _obj.active = false;
  });
  
  us_setter([...us_getter]);
}

function closeLayerPop(evt) {
  const target = evt.target.closest('.LAYER_POP.ACTIVE');
  $(target).removeClass('ACTIVE');
}

/**
 * 일차함수
 * @param x1
 * @param y1
 * @param x2
 * @param y2
 * @param target_x
 * @returns {number}
 */
function linearFunction(x1, y1, x2, y2, target_x) {
  const a = (y2 - y1) / (x2 - x1);
  const b = y1 - a * x1;
  const target_y = a * target_x + b;
  return target_y;
}

/**
 *
 * @param hScrollWapper_id
 */
function setHScrolling(hScrollWapper_id) {
  const el_html = document.querySelector('html');
  const app_wid = parseInt(el_html.getAttribute(`data-app-wid`));
  if( app_wid < 1280 ) return;
  
  const $hScrollWapper = document.querySelector(hScrollWapper_id);
  if(!$hScrollWapper) return;
  $hScrollWapper.style.willChange = "auto";
  const $parent = $hScrollWapper.parentElement;
  const parent_wid = $parent.clientWidth;
  const wrapper_wid = $hScrollWapper.clientWidth;
  const wrapper_hei = $hScrollWapper.clientHeight;
  
  // $hScrollWapper 너비 만큼 부모 높이를 키워줌
  $parent.style.height = `${wrapper_wid}px`;
  
  // 부모의 position top 기준으로
  const parent_top = $parent.getBoundingClientRect().top;
  
  if (parent_top < 0) {
    // wrapper 고정
    $hScrollWapper.style.position = `fixed`;
    
    // 다음 섹션이 $hScrollWapper 의 높이 만큼 치고 올라왔을때 자연스럽게 연결
    const k = parent_top + wrapper_wid - wrapper_hei;
    if (k < 0) {
      $hScrollWapper.style.top = `${k}px`;
    } else {
      $hScrollWapper.style.top = `0`;
    }
    
  } else {
    // wrapper 고정 해제
    $hScrollWapper.style.position = `static`;
    // $hScrollWapper.style.top = `auto`;
  }
  
  // $hScrollWapper 가로 위치를 부모의 세로스크롤과 일차 함수로 연결
  let targetX = linearFunction(0, 0, (-1 * (wrapper_wid - wrapper_hei)), ((-1 * wrapper_wid) + parent_wid), parent_top);
  if (targetX > 0) targetX = 0;
  const x_limit = -1 * (wrapper_wid - parent_wid);
  if (targetX < x_limit) targetX = x_limit;
  $hScrollWapper.style.transform = `translateX(${targetX}px)`;
}

export {
  $,
  Header,
  toJsxStrArray,
  activeOneItem,
  inactiveAllItem,
  closeLayerPop,
  linearFunction,
  setHScrolling,
}