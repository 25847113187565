const section_2_data = [
  {
    question:"커머스온샵은 다른 솔루션들과 어떻게 다른가요?",
    answer:"시중의 솔루션은 소호 사업자부터 중견기업까지 모두 사용이 가능하도록 구성되어 있는 만큼, 특정사용자들은 사용하지 않는 기능들이 다수 포함되어 있습니다. 이로인한 속도 문제나, 화면의 복잡도가 높아지는 등의 단점들이 있습니다.  커머스온샵은 운영에 꼭 필요한 핵심기능들은 모두 담아 빠른 속도와 간결한 UI/UX , 그리고 편리한 사용성까지 반영된 특징을 지니고 있다고 볼 수 있습니다. ",
    active : true,
  },
  {
    question:"개발 진행은 어떤 절차로 이루어지는가요?",
    answer:"가장먼저 클라이언트의 미팅을 통해 요구사항을 파악하고, 컨설팅을 병행하며 작업 범위를 산정합니다.  요청하시는 내용에 따라 커스텀 범위와 견적을 산정하고 디자인, 퍼블리싱, 개발을 진행합니다. 특히 개발작업만큼 비중을 두고 있는 부분은 오픈 후 안정화 기간이라고 볼 수 있습니다.\n" +
      "* 현업미팅 -> 요구사항정의 -> 커스텀정리 -> 계약범위 조율 -> 계약확정 -> 개발진행 -> 검수 -> 사이트오픈 -> 안정화",
    active : false,
  },
  {
    question:"컨설팅의 범위가 어떻게 되나요?",
    answer:"고객사의 상황과 니즈에 따라, 사업단계별, 또는 비지니스 계획별로 컨설팅 범위는 크게 달라집니다.  예를들면, 이제 막 사업을 시작하려는 고객사의 경우는 기본적인 사이트 구축부터, 내부 팀별 구성 및 오픈 후 초기운영, 마케팅 지원 등의 범위로 컨설팅이 될 수 있으며,  어느정도 비지니스 진행도중 디지털트랜스포메이션의 과제나, 중대한 경영 의사결정에 앞서 기술적 자문등이 필요하신 경우가 있을 수 있고, 특정 영역 (물류, 상품운영 ..) 에 대한 디테일한 실행방법이나 기술적 지원등이 될 수도 있습니다.\n" +
      "즉, 이커머스에 관한 모든 컨설팅이 가능합니다.",
    active : false,
  },
  {
    question:"개발 후 유지보수는 어떻게 진행되나요?",
    answer:"사이트 구축 등의 프로젝트를 진행하는 동안에, 오픈 후 운영에 필요한 공수를 산정하여 그에따라 유지보수 기간 및 배정인력을 결정하게 됩니다. 계약기간은 특별히 정해져 있지 않지만 통상 1년 정도 단위로 체결하고 있으며, 필요에 따라 조정 가능합니다.  인력의 경우 이커머스 운영지원에 필요한 ‘PM/기획, 디자인, 퍼블리싱, 개발’ 을 기준으로 공수를 산정합니다.\n" +
      "* 하자보수는 유지보수와 상관없이 특정기간 지원하고 있습니다.",
    active : false,
  },
  {
    question:"쇼핑이 아닌 카테고리의 경우도 컨설팅이나 개발이 가능한가요?",
    answer:"이커머스 분야는 쇼핑외에도 많은 카테고리가 존재합니다. 온라인으로 재화를 유통하고 고객에게 서비스를 제공하는 범위에 해당된다면 어느 분야든 컨설팅 및 개발이 가능합니다.",
    active : false,
  },
  {
    question:"'디자인' 페이지에 있는 샘플 디자인으로만 진행이 가능한가요?",
    answer:"홈페이지에 안내되어 있는 디자인은 다양한 디자인 컨셉의 사례를 보다 쉽게 안내드리기 위한 하나의 ‘시안’ 입니다.  즉, 개발 범위와 마찬가지로 고객의 요구수준이나 컨셉에 의해 디자인은 얼마든지 새롭게 구성될 수 있습니다.",
    active : false,
  },
  {
    question:"프로젝트의 대략적인 견적이 어떻게 되나요?",
    answer:"요구사항이나 구현 범위에 따라 견적은 큰 차이가 있습니다. 이 부분은 원하시는 구현 사항을 정하여 문의를 주시면 보다 자세히 안내가 가능합니다.  실질적인 견적은 산출된 공수에 따라  소프트웨어산업진흥법 및  소프트웨어 기술자 노임단가를 기준으로 산정하고 있습니다.",
    active : false,
  },
];

export {
  section_2_data,
}