import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {closeInquiryLayer} from '@/lib/reduxToolkit/inquiry';
import {INQUIRY_TYPES, sendInquiry} from '@/util/inquiry';
import {baseHandleErrors} from '@/util/react-hook-form';
import {useForm} from 'react-hook-form';

function LayerPopBasicInquiry({type}) {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const {register, handleSubmit, watch} = useForm({
    defaultValues: {
      type: type ?? "default"
    },
    shouldFocusError: false
  });

  const closeLayer = useCallback(() => {
    const {type, ...data} = watch();
    const hasData = Object.values(data).join('').length > 0;

    if(!hasData) {
      dispatch(closeInquiryLayer());
      return;
    }

    if (window.confirm("입력하신 정보가 삭제됩니다. 닫으시겠습니까?")) {
      dispatch(closeInquiryLayer());
    }
  }, []);

  const onError = useCallback(({name, email, contact, budget, category, referenceSite, content}) => {
    baseHandleErrors([name, email, contact, budget, category, referenceSite, content]);
  }, []);

  const onSubmit = async (data) => {
    if (isFetching) {
      return;
    }

    if(data.type === 'default') {
      alert("문의 유형을 선택해주세요.");
      return;
    }

    setIsFetching(true);

    try {
      await sendInquiry(data);
      alert('문의 메일이 정상적으로 접수되었습니다. 담당자 통해서 빠르게 연락드리도록 하겠습니다. 감사합니다');
      dispatch(closeInquiryLayer());

    } catch (error) {
      console.error(error);
      alert('문의메일 제출이 실패했습니다.');

    } finally {
      setIsFetching(false);
    }
  };

  const isBuildSite = watch('type') === 'build-site';

  return (
    <div className={`LAYER_POP ACTIVE`}>
      <div className={`BG`} onClick={closeLayer}></div>
      <div className={`INNER w-full md:max-w-[730px]`}>
        <div className={`HEAD`}>
          <button className={`CLOSE_BTN`} onClick={closeLayer}></button>
        </div>
        <div className={`BODY`}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className={`
                font-[600] text-[24px] leading-[36px] text-white
                xl:font-[600] xl:text-[36px] xl:leading-[44px] xl:text-white tracking-[-0.5px]
                `}>
              Leave us a message
            </div>
            <div className={`mt-[8px] font-[300] text-[14px] leading-[24px] text-white`}>
              이커머스와 관련된 문의라면 무엇이든지 환영합니다.
              지금 바로 문의 주세요.
            </div>
            <select className={`SELECT w-full max-w-[380px] mt-[27px]`} {...register("type")}>
              {INQUIRY_TYPES.map(({value, name}) => (
                <option key={value} value={value}>{name}</option>
              ))}
            </select>
            <div className={`mt-[35px] space-y-[35px]`}>
              <div className={`INPUT_BOX`}>
                <label>이름</label>
                <input className={`INPUT`} type="text" autoFocus placeholder="담당자명을 입력해주세요." {...register("name", NAME_OPTIONS)}/>
              </div>
              <div className={`INPUT_BOX`}>
                <label>이메일</label>
                <input className={`INPUT`} type="email" placeholder="이메일을 입력해주세요." {...register("email", EMAIL_OPTIONS)}/>
              </div>
              <div className={`INPUT_BOX`}>
                <label>연락처</label>
                <input className={`INPUT`} type="number" placeholder="연락처를 입력해주세요. (- 없이 숫자만)" {...register("contact", CONTACT_OPTIONS)}/>
              </div>
              {!isBuildSite ? null : (
                <>
                  <div className={`INPUT_BOX`}>
                    <label>구축예산</label>
                    <input className={`INPUT`} type="text" placeholder="구축예산을 입력해주세요." {...register("budget", BUDGET_OPTIONS)}/>
                  </div>
                  <div className={`INPUT_BOX`}>
                    <label>카테고리</label>
                    <input className={`INPUT`} type="text" placeholder="비지니스카테고리(업종)를 입력해주세요." {...register("category", CATEGORY_OPTIONS)}/>
                  </div>
                  <div className={`INPUT_BOX`}>
                    <label>참고사이트</label>
                    <input className={`INPUT`} type="url" placeholder="참고사이트를 입력해주세요." {...register("referenceSite", REFERENCE_SITE_OPTIONS)}/>
                  </div>
                </>
              )}
              <textarea className={`block w-full h-[254px] resize-none px-[24px] py-[20px] font-[500] text-[14px] leading-[16px] text-[var(--neutral-90)] placeholder-[var(--neutral-50)] `} placeholder="내용을 입력해주세요" {...register("content", CONTENT_OPTIONS)}></textarea>
            </div>
            <div className={`mt-[40px] xl:mt-[60px] space-x-[7px] text-center xl:text-right`}>
              <button type="button" className={`BTN BTN-WHITE w-[130px]`} onClick={closeLayer}>취소하기
              </button>
              <button className={`BTN BTN-PRIMARY w-[130px] ${isFetching ? "cursor-wait" : ""}`}>전송하기</button>
            </div>
          </form>
        </div>
        {/*<div className={`FOOT`}>FOOT</div>*/}
      </div>
    </div>
  );
}

const NAME_OPTIONS = {
  required: {
    value: true,
    message: "이름은 필수입니다."
  },
};

const EMAIL_OPTIONS = {
  required: {
    value: true,
    message: '이메일은 필수입니다.',
  },
  pattern: {
    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g,
    message: "이메일 양식에 맞게 작성해주세요.",
  }
};

const CONTACT_OPTIONS = {
  required: {
    value: true,
    message: "연락처는 필수입니다."
  },
};

const BUDGET_OPTIONS = {
  required: {
    value: true,
    message: "구축예산은 필수입니다.",
  }
};

const CATEGORY_OPTIONS = {
  required: {
    value: true,
    message: "비즈니스카테고리(업종)는 필수입니다."
  }
};

const REFERENCE_SITE_OPTIONS = {
  pattern: {
    value: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
    message: "URL 양식에 맞게 작성해주세요"
  }
};

const CONTENT_OPTIONS = {
  required: {
    value: true,
    message: "내용은 필수입니다."
  },
};

export default LayerPopBasicInquiry;
