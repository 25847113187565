import PageMain from "@/page/PageMain";
import PageIntroduction from "@/page/PageIntroduction";
import PageNotFound from "@/page/PageNotFound";
import LayoutBasic from "@/layout/LayoutBasic";
import LayoutEmpty from "@/layout/LayoutEmpty";
import {Route, Routes} from "react-router-dom";
import PageTemplate from "@/page/PageTemplate";
import PageSitemap from "@/page/PageSitemap";
import PageSwiperjs from "@/page/PageSwiperjs";
import PageDesign from "@/page/PageDesign";
import PageCustomerService from "@/page/PageCustomerService";
import PageConsulting from "@/page/PageConsulting";
import PageLayerPopSample from "@/page/PageLayerPopSample";
import PagePersonalDataPolicy from "@/page/PagePersonalDataPolicy";

function routeSetting() {
  return (
    <Routes>
      <Route element={<LayoutBasic/>}>
        <Route path="/" element={<PageMain title='메인'/>}/>
        <Route path="/PageIntroduction" element={<PageIntroduction title='솔루션소개'/>}/>
        <Route path="/PageDesign" element={<PageDesign title='디자인'/>}/>
        <Route path="/PageCustomerService" element={<PageCustomerService title='고객센터'/>}/>
        <Route path="/PageConsulting" element={<PageConsulting title='컨설팅'/>}/>
        <Route path="/PageTemplate" element={<PageTemplate title='UI 템플릿'/>}/>
        <Route path="/PagePersonalDataPolicy" element={<PagePersonalDataPolicy title='개인정보처리방침'/>}/>
      </Route>
      <Route element={<LayoutEmpty/>}>
        <Route path="/PageSwiperjs" element={<PageSwiperjs title='swiperjs 샘플'/>}/>
        <Route path="/PageLayerPopSample" element={<PageLayerPopSample title='레이어 팝업 샘플'/>}/>
        <Route path="/PageSitemap" element={<PageSitemap title='사이트맵'/>}/>
      </Route>
      {/* 빈 레이아웃 */}
      <Route>
        <Route path="*" element={<PageNotFound title='페이지 404'/>}/>
      </Route>
    </Routes>
  );
}

export default routeSetting;