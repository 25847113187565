export function baseHandleErrors(errorList) {
  for (const error of errorList) {
    if (!error) {
      continue;
    }

    const {message} = error;

    if (!message) {
      console.error('error message를 지정안했음. register중에 ValidationValueMessage 타입으로 쓰지않은 값이 있는지 확인');
      throw new TypeError('잠시 후 다시 시도해주세요.');
    }

    alert(message);
    error.ref?.focus?.();
    break;
  }
}
